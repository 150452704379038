import React, { useMemo } from 'react'
import ReactFlow, {
  Background,
  Controls,
  Edge,
  Node,
  NodeProps,
  Position,
  Handle,
  Connection,
  ReactFlowProvider,
  Panel,
} from 'reactflow'
import 'reactflow/dist/style.css'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/shared/components/ui/tooltip'
import { Badge } from '@/shared/components/ui/badge'
import ComponentLibrary from './component-library'

interface AgentNodeData {
  label: string
  type: string
  status: string
  version: string
  inputs?: string[]
  outputs?: Record<string, string>
}

// Define AgentNode component outside main component
const AgentNode: React.FC<NodeProps<AgentNodeData>> = ({ data }) => {
  return (
    <TooltipProvider>
      <div className="relative bg-background rounded-lg shadow-lg border border-border p-4 min-w-[280px]">
        <div className="flex items-center justify-between mb-3">
          <h3 className="text-base font-medium text-foreground truncate max-w-[180px]">
            {data.label}
          </h3>
          <Badge
            variant={data.status === 'active' ? 'success' : 'secondary'}
            className="text-xs shrink-0"
          >
            {data.status}
          </Badge>
        </div>

        <div className="flex justify-between text-xs text-muted-foreground mb-6">
          <span className="px-2 py-1 bg-muted rounded">v{data.version}</span>
          <span className="px-2 py-1 bg-muted rounded">{data.type}</span>
        </div>

        {/* Input Handles */}
        {data.inputs?.map((input: string, index: number) => (
          <Tooltip key={`input-${index}`}>
            <TooltipTrigger asChild>
              <Handle
                type="target"
                position={Position.Left}
                id={input}
                style={{ top: `${(index + 1) * 20}%` }}
                className="w-3 h-3 !bg-blue-500 border-2 border-background"
              />
            </TooltipTrigger>
            <TooltipContent side="left" className="max-w-[200px]">
              <p className="font-medium">Input</p>
              <p className="text-xs text-muted-foreground">{input}</p>
            </TooltipContent>
          </Tooltip>
        ))}

        {/* Output Handles */}
        {Object.entries(data.outputs || {}).map(([id, type], index) => (
          <Tooltip key={`output-${index}`}>
            <TooltipTrigger asChild>
              <Handle
                type="source"
                position={Position.Right}
                id={id}
                style={{ top: `${(index + 1) * 20}%` }}
                className="w-3 h-3 !bg-green-500 border-2 border-background"
              />
            </TooltipTrigger>
            <TooltipContent side="right" className="max-w-[200px]">
              <p className="font-medium">Output: {id}</p>
              <p className="text-xs text-muted-foreground">{type}</p>
            </TooltipContent>
          </Tooltip>
        ))}
      </div>
    </TooltipProvider>
  )
}

interface WorkflowCanvasProps {
  nodes: Node<AgentNodeData>[]
  edges: Edge[]
  onNodesChange?: (changes: any[]) => void
  onEdgesChange?: (changes: any[]) => void
  onConnect?: (params: Connection) => void
  onNodeClick?: (event: React.MouseEvent, node: Node<AgentNodeData>) => void
  onAddNode?: (component: any) => void
}

export const WorkflowCanvas: React.FC<WorkflowCanvasProps> = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  onNodeClick,
  onAddNode,
}) => {
  // Memoize nodeTypes to prevent recreation
  const nodeTypes = useMemo(() => ({ agent: AgentNode }), [])

  return (
    <ReactFlowProvider>
      <div className="h-full w-full bg-background relative">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          fitView
          minZoom={0.1}
          maxZoom={4}
          defaultEdgeOptions={{
            style: { stroke: 'hsl(var(--muted-foreground))', strokeWidth: 2 },
            type: 'smoothstep',
          }}
          proOptions={{ hideAttribution: true }}
        >
          <Background className="bg-muted" />
          <Controls className="bg-background border border-border" />
          <Panel
            position="top-left"
            className="bg-background border border-border rounded-lg p-2 z-50"
          >
            <div className="text-sm text-muted-foreground">
              Use mouse wheel to zoom • Drag canvas to pan
            </div>
          </Panel>
        </ReactFlow>
        {onAddNode && (
          <div className="absolute top-0 right-0 z-50">
            <ComponentLibrary onAddNode={onAddNode} />
          </div>
        )}
      </div>
    </ReactFlowProvider>
  )
}

export default WorkflowCanvas
