import React from 'react'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { Grid } from '@/shared/components/ui/grid'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'

export const _RepairOverviewPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    { month: 'January', repairs: 85, completed: 80 },
    { month: 'February', repairs: 95, completed: 90 },
    { month: 'March', repairs: 120, completed: 115 },
    { month: 'April', repairs: 110, completed: 105 },
    { month: 'May', repairs: 130, completed: 125 },
    { month: 'June', repairs: 150, completed: 145 },
  ]

  const mockRepairTypesData = [
    { label: 'Sole Replacement', value: 250 },
    { label: 'Cleaning', value: 180 },
    { label: 'Stitching', value: 120 },
    { label: 'Other', value: 50 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Repair Overview</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            Export Data
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            New Repair
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Total Repairs
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              690
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+15.2% vs Last Month</Text>
            <Text>Target: 750</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Completion Rate
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              96.7%
            </Text>
            <Text className="text-neutral-400">Current Rate</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+1.5% vs Last Month</Text>
            <Text>Target: 98%</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Average Time
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              3.2
            </Text>
            <Text className="text-neutral-400">Days per Repair</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+0.3 days vs Last Month</Text>
            <Text>Target: 3.0</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Repairs Timeline
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockTimelineData}
              dataKeys={['repairs', 'completed']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Repair Types
          </Text>
          <div className="w-full h-64">
            <BarChart
              data={mockRepairTypesData}
              className="w-full"
              height={256}
              yLabel="Count"
            />
          </div>
        </Card>
      </Grid>

      <Card className="bg-neutral-800 p-4 rounded-lg">
        <Text variant="h3" className="mb-4">
          Recent Activity
        </Text>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <Text variant="label">Repair #REP00545897</Text>
              <Text variant="body">Sole replacement completed</Text>
            </div>
            <Text variant="body" className="text-neutral-400">
              2 hours ago
            </Text>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <Text variant="label">Repair #REP00354861</Text>
              <Text variant="body">Cleaning in progress</Text>
            </div>
            <Text variant="body" className="text-neutral-400">
              4 hours ago
            </Text>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <Text variant="label">Repair #REP00354862</Text>
              <Text variant="body">Assessment completed</Text>
            </div>
            <Text variant="body" className="text-neutral-400">
              6 hours ago
            </Text>
          </div>
        </div>
      </Card>
    </div>
  )
}
