import { createBrowserRouter } from 'react-router-dom'
import { _kioskRoutes as kioskRoutes } from './app/routes'
import { AdminRoutes } from './admin/routes'

export const _router = createBrowserRouter(
  [
    {
      path: '/',
      element: kioskRoutes[0].element,
      children: [
        {
          index: true,
          element: kioskRoutes[0].children?.find((route): void => route.index)
            ?.element,
        },
        ...(kioskRoutes[0].children?.filter((route): void => !route.index) ||
          []),
      ],
    },
    ...AdminRoutes(),
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
)
