import { Node, Edge } from 'reactflow'
import { AgentNodeData } from './types/workflow'

interface ValidationError {
  type: 'error' | 'warning'
  message: string
  nodeId?: string
  edgeId?: string
}

interface ValidationResult {
  isValid: boolean
  errors: ValidationError[]
  warnings: ValidationError[]
}

export function validateWorkflow(
  nodes: Node<AgentNodeData>[],
  edges: Edge[]
): ValidationResult {
  const errors: ValidationError[] = []
  const warnings: ValidationError[] = []

  // Check for empty workflow
  if (nodes.length === 0) {
    errors.push({
      type: 'error',
      message: 'Workflow must contain at least one node',
    })
    return { isValid: false, errors, warnings }
  }

  // Check for disconnected nodes
  nodes.forEach((node) => {
    const hasConnections = edges.some(
      (edge) => edge.source === node.id || edge.target === node.id
    )
    if (!hasConnections) {
      warnings.push({
        type: 'warning',
        message: 'Node is not connected to any other nodes',
        nodeId: node.id,
      })
    }
  })

  // Check for required inputs
  nodes.forEach((node) => {
    if (!node.data?.inputs) return // Skip if no inputs defined

    const nodeInputs = node.data.inputs
    const connectedInputs = edges
      .filter((edge) => edge.target === node.id)
      .map((edge) => edge.targetHandle)

    nodeInputs.forEach((input) => {
      if (!connectedInputs.includes(input)) {
        errors.push({
          type: 'error',
          message: `Required input "${input}" is not connected`,
          nodeId: node.id,
        })
      }
    })
  })

  // Check for cycles
  if (hasCycle(nodes, edges)) {
    errors.push({
      type: 'error',
      message: 'Workflow contains cycles, which are not allowed',
    })
  }

  // Check for type compatibility
  edges.forEach((edge) => {
    const sourceNode = nodes.find((n) => n.id === edge.source)
    const targetNode = nodes.find((n) => n.id === edge.target)

    if (sourceNode && targetNode && edge.sourceHandle && edge.targetHandle) {
      const outputType = sourceNode.data?.outputs?.[edge.sourceHandle]
      const inputType = targetNode.data?.inputs?.includes(edge.targetHandle)
        ? 'any'
        : undefined

      if (!outputType || !inputType) {
        errors.push({
          type: 'error',
          message: `Invalid connection: missing input/output types`,
          edgeId: edge.id,
        })
      }
    }
  })

  // Check for duplicate connections
  const connectionMap = new Map<string, number>()
  edges.forEach((edge) => {
    const key = `${edge.target}-${edge.targetHandle}`
    connectionMap.set(key, (connectionMap.get(key) || 0) + 1)
  })

  connectionMap.forEach((count, key) => {
    if (count > 1) {
      const [nodeId, input] = key.split('-')
      errors.push({
        type: 'error',
        message: `Input "${input}" has multiple connections`,
        nodeId,
      })
    }
  })

  // Performance warnings
  if (nodes.length > 20) {
    warnings.push({
      type: 'warning',
      message: 'Large workflows may impact performance',
    })
  }

  return {
    isValid: errors.length === 0,
    errors,
    warnings,
  }
}

function hasCycle(nodes: Node[], edges: Edge[]): boolean {
  const visited = new Set<string>()
  const recursionStack = new Set<string>()

  function dfs(nodeId: string): boolean {
    visited.add(nodeId)
    recursionStack.add(nodeId)

    const outgoingEdges = edges.filter((edge) => edge.source === nodeId)
    for (const edge of outgoingEdges) {
      if (!visited.has(edge.target)) {
        if (dfs(edge.target)) return true
      } else if (recursionStack.has(edge.target)) {
        return true
      }
    }

    recursionStack.delete(nodeId)
    return false
  }

  for (const node of nodes) {
    if (!visited.has(node.id)) {
      if (dfs(node.id)) return true
    }
  }

  return false
}
