import { setupWorker } from 'msw/browser'
import { _handlers as handlers } from './handlers'
export const worker = setupWorker(...handlers)
// Create a promise that resolves when MSW is ready
export const _mswReady = worker
  .start({
    onUnhandledRequest: 'bypass',
    serviceWorker: {
      url: '/mockServiceWorker.js',
    },
  })
  .catch((error): void => {
    console.error('MSW initialization failed:', error)
    return Promise.reject(error)
  })
