import { RouteObject } from 'react-router-dom'
import { _HomePage as HomePage } from '@/features/home/HomePage'

export const _kioskRoutes: RouteObject[] = [
  {
    path: '/',
    element: <HomePage />,
    children: [{ index: true, element: <HomePage /> }],
  },
]
