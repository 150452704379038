import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/shared/components/ui/card';
import type { LearningSystemImpl } from '@/domain/ai/learning/core/learning-system';
import type { PatternInsight } from '@/domain/ai/learning/types';
interface Props {
    learningSystem: LearningSystemImpl;
    maxEvents?: number;
    refreshInterval?: number;
}
export function LearningStream({ learningSystem, maxEvents = 10, refreshInterval = 3000, // Default to 3 seconds
 }: Props): void {
    const [insights, setInsights] = useState<PatternInsight[]>([]);
    useEffect((): void => {
        // Initial fetch
        setInsights(learningSystem.getLatestInsights());
        // Set up interval for real-time updates
        const interval = setInterval((): void => {
            const newInsights = learningSystem.getLatestInsights();
            setInsights(prev => {
                // Combine new insights with existing ones, keeping the most recent
                const combined = [...newInsights, ...prev];
                // Remove duplicates based on pattern and timestamp
                const unique = combined.filter((insight, index, self) => index === self.findIndex(i => i.pattern === insight.pattern));
                // Keep only the maximum number of events
                return unique.slice(0, maxEvents);
            });
        }, refreshInterval);
        return (): void => clearInterval(interval);
    }, [learningSystem, maxEvents, refreshInterval]);
    const getSeverityColor = (severity: PatternInsight['severity']): void => {
        switch (severity) {
            case 'high':
                return 'text-red-500';
            case 'medium':
                return 'text-yellow-500';
            case 'low':
                return 'text-green-500';
            default:
                return 'text-gray-500';
        }
    };
    const getTypeIcon = (type: PatternInsight['type']): void => {
        switch (type) {
            case 'improvement':
                return '↗';
            case 'degradation':
                return '↘';
            case 'warning':
                return '⚠';
            default:
                return '•';
        }
    };
    return (<Card>
      <CardHeader>
        <CardTitle>Learning Stream</CardTitle>
      </CardHeader>
      <CardContent>
        <div className='space-y-4'>
          {insights.map((insight, index): void => (<div key={`${insight.pattern}-${index}`} className='p-4 border rounded-lg bg-white/5 border-gray-800 transition-all duration-300 hover:bg-white/10'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center space-x-2'>
                  <span className='text-lg'>{getTypeIcon(insight.type)}</span>
                  <span className={`text-sm font-medium ${getSeverityColor(insight.severity)}`}>
                    {insight.severity.toUpperCase()}
                  </span>
                </div>
                <span className='text-xs text-gray-500'>{new Date().toLocaleTimeString()}</span>
              </div>
              <p className='mt-2 text-sm'>{insight.message}</p>
              <div className='mt-2 text-xs text-gray-500'>
                Pattern: {insight.pattern} | Frequency: {insight.frequency} | Confidence:{' '}
                {(insight.confidence * 100).toFixed(1)}%
                {insight.metric && insight.value && (<span className='ml-2'>
                    | {insight.metric}: {(insight.value * 100).toFixed(1)}%
                  </span>)}
              </div>
            </div>))}
          {insights.length === 0 && (<div className='text-center py-12 text-gray-500'>No insights to display</div>)}
        </div>
      </CardContent>
    </Card>);
}
