import React, { useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/shared/components/ui/select'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'

interface WorkflowTemplate {
  id: string
  name: string
  description: string
  category: string
  nodes: any[]
  edges: any[]
}

const templates: WorkflowTemplate[] = [
  {
    id: 'personal-shopper',
    name: 'Personal Shopper',
    description:
      'A workflow for providing personalized shopping recommendations',
    category: 'commerce',
    nodes: [
      {
        id: '1',
        type: 'agent',
        position: { x: 100, y: 100 },
        data: {
          label: 'Customer Insight Agent',
          type: 'customer-insight',
          status: 'active',
          version: '1.0',
          inputs: ['customer_data'],
          outputs: { insights: 'CustomerInsights' },
        },
      },
      {
        id: '2',
        type: 'agent',
        position: { x: 400, y: 100 },
        data: {
          label: 'Size/Fit Agent',
          type: 'size-fit',
          status: 'active',
          version: '1.0',
          inputs: ['customer_measurements'],
          outputs: { recommendations: 'SizeRecommendations' },
        },
      },
      {
        id: '3',
        type: 'agent',
        position: { x: 700, y: 100 },
        data: {
          label: 'Product Discovery Agent',
          type: 'product-discovery',
          status: 'active',
          version: '1.0',
          inputs: ['customer_preferences'],
          outputs: { products: 'ProductList' },
        },
      },
    ],
    edges: [
      {
        id: 'e1',
        source: '1',
        target: '2',
        sourceHandle: 'insights',
        targetHandle: 'customer_measurements',
      },
      {
        id: 'e2',
        source: '2',
        target: '3',
        sourceHandle: 'recommendations',
        targetHandle: 'customer_preferences',
      },
    ],
  },
]

interface WorkflowTemplateSelectorProps {
  onSelect: (template: WorkflowTemplate) => void
}

export const WorkflowTemplateSelector: React.FC<
  WorkflowTemplateSelectorProps
> = ({ onSelect }) => {
  const [selectedCategory, setSelectedCategory] = useState('all')

  const categories = ['all', 'commerce', 'support', 'operations']

  const filteredTemplates =
    selectedCategory === 'all'
      ? templates
      : templates.filter((t) => t.category === selectedCategory)

  return (
    <div className="space-y-4">
      <Select value={selectedCategory} onValueChange={setSelectedCategory}>
        <SelectTrigger>
          <SelectValue placeholder="Select category" />
        </SelectTrigger>
        <SelectContent>
          {categories.map((category) => (
            <SelectItem key={category} value={category}>
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <div className="grid gap-4">
        {filteredTemplates.map((template) => (
          <Card key={template.id} className="p-4">
            <div className="flex items-start justify-between">
              <div>
                <h4 className="text-sm font-medium">{template.name}</h4>
                <p className="text-sm text-muted-foreground mt-1">
                  {template.description}
                </p>
              </div>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => onSelect(template)}
              >
                Use Template
              </Button>
            </div>
          </Card>
        ))}
        {filteredTemplates.length === 0 && (
          <p className="text-sm text-muted-foreground text-center py-4">
            No templates available in this category
          </p>
        )}
      </div>
    </div>
  )
}

export default WorkflowTemplateSelector
