/** @jsxImportSource react */
import { forwardRef } from 'react'
import { Node } from 'reactflow'
import { Input } from '@/shared/components/ui/input'
import { Label } from '@/shared/components/ui/label'
import { Badge } from '@/shared/components/ui/badge'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/components/ui/accordion'
import { Switch } from '@/shared/components/ui/switch'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/shared/components/ui/select'
import { Textarea } from '@/shared/components/ui/textarea'
import { FileUpload } from '@/shared/components/ui/file-upload'
import { MultiSelect } from '@/shared/components/ui/multi-select'

interface AgentNodeData {
  label: string
  type: string
  status: string
  config: Record<string, any>
}

interface ConfigurationPanelProps {
  node: Node<AgentNodeData>
  onConfigChange: (nodeId: string, key: string, value: any) => void
}

const ConfigurationPanel = forwardRef<HTMLDivElement, ConfigurationPanelProps>(
  ({ node, onConfigChange }, ref) => {
    const handleFileUpload = (key: string) => (files: File | File[]) => {
      onConfigChange(node.id, key, files)
    }

    const renderReturnsProcessingConfig = () => (
      <AccordionItem value="returns-processing">
        <AccordionTrigger>Returns Processing Configuration</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-6">
            {/* Return Window Duration */}
            <div className="space-y-2">
              <Label>Return Window Duration</Label>
              <div className="flex space-x-2">
                <Input
                  type="number"
                  value={node.data.config.returnWindow?.duration || 30}
                  onChange={(e) =>
                    onConfigChange(
                      node.id,
                      'returnWindow.duration',
                      e.target.value
                    )
                  }
                />
                <Select
                  value={node.data.config.returnWindow?.unit || 'days'}
                  onValueChange={(value) =>
                    onConfigChange(node.id, 'returnWindow.unit', value)
                  }
                >
                  <SelectTrigger className="w-[100px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="days">Days</SelectItem>
                    <SelectItem value="weeks">Weeks</SelectItem>
                    <SelectItem value="months">Months</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Returns Policy Document */}
            <div className="space-y-2">
              <Label>Returns Policy</Label>
              <FileUpload
                accept=".pdf,.doc,.docx"
                onChange={handleFileUpload('returnsPolicy')}
              />
            </div>

            {/* Condition Requirements */}
            <div className="space-y-2">
              <Label>Condition Requirements</Label>
              <MultiSelect
                options={[
                  { value: 'new_with_tags', label: 'New with tags' },
                  { value: 'like_new', label: 'Like new' },
                  { value: 'gently_used', label: 'Gently used' },
                  { value: 'visible_wear', label: 'Visible wear' },
                ]}
                value={node.data.config.conditionRequirements || []}
                onChange={(value) =>
                  onConfigChange(node.id, 'conditionRequirements', value)
                }
              />
            </div>

            {/* Category Exclusions */}
            <div className="space-y-2">
              <Label>Category Exclusions</Label>
              <MultiSelect
                options={[]} // This should be populated from product catalog
                value={node.data.config.categoryExclusions || []}
                onChange={(value) =>
                  onConfigChange(node.id, 'categoryExclusions', value)
                }
              />
            </div>

            {/* Return Label Generation */}
            <div className="space-y-2">
              <Label>Return Label Generation</Label>
              <div className="space-y-4">
                <Switch
                  checked={node.data.config.returnLabel?.enabled || false}
                  onCheckedChange={(checked) =>
                    onConfigChange(node.id, 'returnLabel.enabled', checked)
                  }
                />
                {node.data.config.returnLabel?.enabled && (
                  <>
                    <Select
                      value={node.data.config.returnLabel?.carrier}
                      onValueChange={(value) =>
                        onConfigChange(node.id, 'returnLabel.carrier', value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Carrier" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="ups">UPS</SelectItem>
                        <SelectItem value="fedex">FedEx</SelectItem>
                        <SelectItem value="usps">USPS</SelectItem>
                      </SelectContent>
                    </Select>
                    <Select
                      value={node.data.config.returnLabel?.format}
                      onValueChange={(value) =>
                        onConfigChange(node.id, 'returnLabel.format', value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Label Format" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="pdf">PDF</SelectItem>
                        <SelectItem value="zpl">ZPL</SelectItem>
                      </SelectContent>
                    </Select>
                  </>
                )}
              </div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    )

    const renderRepairAssessmentConfig = () => (
      <AccordionItem value="repair-assessment">
        <AccordionTrigger>Repair Assessment Configuration</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-6">
            {/* Repair Manual Repository */}
            <div className="space-y-2">
              <Label>Repair Manual Repository</Label>
              <FileUpload
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                multiple
                onChange={handleFileUpload('repairManuals')}
              />
            </div>

            {/* Service Catalog */}
            <div className="space-y-2">
              <Label>Service Catalog</Label>
              <div className="space-y-4">
                {(node.data.config.serviceCatalog || []).map(
                  (service: any, index: number) => (
                    <div key={index} className="space-y-2 p-4 border rounded">
                      <Input
                        placeholder="Service Name"
                        value={service.name}
                        onChange={(e) =>
                          onConfigChange(
                            node.id,
                            `serviceCatalog.${index}.name`,
                            e.target.value
                          )
                        }
                      />
                      <div className="flex space-x-2">
                        <Input
                          type="number"
                          placeholder="Duration (hours)"
                          value={service.duration}
                          onChange={(e) =>
                            onConfigChange(
                              node.id,
                              `serviceCatalog.${index}.duration`,
                              e.target.value
                            )
                          }
                        />
                        <Input
                          type="number"
                          placeholder="Cost"
                          value={service.cost}
                          onChange={(e) =>
                            onConfigChange(
                              node.id,
                              `serviceCatalog.${index}.cost`,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* Warranty Information */}
            <div className="space-y-2">
              <Label>Warranty Terms</Label>
              <FileUpload
                accept=".pdf,.doc,.docx"
                onChange={handleFileUpload('warrantyTerms.file')}
              />
              <Input
                type="number"
                placeholder="Coverage Period (months)"
                value={node.data.config.warrantyTerms?.period}
                onChange={(e) =>
                  onConfigChange(
                    node.id,
                    'warrantyTerms.period',
                    e.target.value
                  )
                }
              />
              <MultiSelect
                options={[
                  {
                    value: 'manufacturing_defects',
                    label: 'Manufacturing Defects',
                  },
                  { value: 'material_defects', label: 'Material Defects' },
                  { value: 'workmanship', label: 'Workmanship' },
                ]}
                value={node.data.config.warrantyTerms?.coveredIssues || []}
                onChange={(value) =>
                  onConfigChange(node.id, 'warrantyTerms.coveredIssues', value)
                }
              />
              <Textarea
                placeholder="Exclusions"
                value={node.data.config.warrantyTerms?.exclusions}
                onChange={(e) =>
                  onConfigChange(
                    node.id,
                    'warrantyTerms.exclusions',
                    e.target.value
                  )
                }
              />
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    )

    const renderRecommerceConfig = () => (
      <AccordionItem value="recommerce">
        <AccordionTrigger>Recommerce Configuration</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-6">
            {/* Product Grading */}
            <div className="space-y-2">
              <Label>Condition Criteria</Label>
              <div className="space-y-4">
                {['A', 'B', 'C'].map((grade) => (
                  <div key={grade} className="space-y-2 p-4 border rounded">
                    <div className="flex items-center space-x-2">
                      <Badge>Grade {grade}</Badge>
                    </div>
                    <Textarea
                      placeholder="Condition Description"
                      value={node.data.config.grading?.[grade]?.description}
                      onChange={(e) =>
                        onConfigChange(
                          node.id,
                          `grading.${grade}.description`,
                          e.target.value
                        )
                      }
                    />
                    <Input
                      type="number"
                      placeholder="Price Impact (%)"
                      value={node.data.config.grading?.[grade]?.priceImpact}
                      onChange={(e) =>
                        onConfigChange(
                          node.id,
                          `grading.${grade}.priceImpact`,
                          e.target.value
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Authentication */}
            <div className="space-y-2">
              <Label>Authentication Markers</Label>
              <FileUpload
                accept=".jpg,.jpeg,.png"
                multiple
                onChange={handleFileUpload('authenticationMarkers')}
              />
            </div>

            {/* Pricing Rules */}
            <div className="space-y-2">
              <Label>Value Calculator</Label>
              <div className="space-y-4">
                <Input
                  type="number"
                  placeholder="Base Price Factor"
                  value={node.data.config.pricing?.baseFactor}
                  onChange={(e) =>
                    onConfigChange(
                      node.id,
                      'pricing.baseFactor',
                      e.target.value
                    )
                  }
                />
                <Input
                  type="number"
                  placeholder="Condition Adjustment"
                  value={node.data.config.pricing?.conditionAdjustment}
                  onChange={(e) =>
                    onConfigChange(
                      node.id,
                      'pricing.conditionAdjustment',
                      e.target.value
                    )
                  }
                />
                <Input
                  type="number"
                  placeholder="Market Trend Factor"
                  value={node.data.config.pricing?.marketTrendFactor}
                  onChange={(e) =>
                    onConfigChange(
                      node.id,
                      'pricing.marketTrendFactor',
                      e.target.value
                    )
                  }
                />
                <Input
                  type="number"
                  placeholder="Seasonal Adjustment"
                  value={node.data.config.pricing?.seasonalAdjustment}
                  onChange={(e) =>
                    onConfigChange(
                      node.id,
                      'pricing.seasonalAdjustment',
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    )

    const renderAgentSpecificConfig = () => {
      switch (node.data.type) {
        case 'returns_processing':
          return renderReturnsProcessingConfig()
        case 'repair_assessment':
          return renderRepairAssessmentConfig()
        case 'recommerce':
          return renderRecommerceConfig()
        default:
          return null
      }
    }

    return (
      <div className="p-4 bg-background text-foreground">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold">Configuration</h2>
          <Badge
            variant={node.data.status === 'active' ? 'default' : 'secondary'}
          >
            {node.data.status}
          </Badge>
        </div>

        <Accordion type="single" collapsible className="space-y-2">
          <AccordionItem value="basic">
            <AccordionTrigger>Basic Information</AccordionTrigger>
            <AccordionContent>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label>Label</Label>
                  <Input
                    value={node.data.label}
                    onChange={(e) =>
                      onConfigChange(node.id, 'label', e.target.value)
                    }
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>

          {renderAgentSpecificConfig()}
        </Accordion>
      </div>
    )
  }
)

export default ConfigurationPanel
