import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { Grid } from '@/shared/components/ui/grid'
import { UserPlus, Download } from 'lucide-react'

interface Technician {
  id: string
  name: string
  specialty: string
  experience: string
  activeRepairs: number
  completedRepairs: number
  avgRepairTime: string
  rating: number
  status: 'Available' | 'Busy' | 'Off Duty'
}

const mockTechnicians: Technician[] = [
  {
    id: 'TECH001',
    name: 'Mike Johnson',
    specialty: 'Sole Replacement',
    experience: '5 years',
    activeRepairs: 3,
    completedRepairs: 245,
    avgRepairTime: '2.8 days',
    rating: 4.8,
    status: 'Busy',
  },
  {
    id: 'TECH002',
    name: 'Lisa Chen',
    specialty: 'Stitching & Patching',
    experience: '3 years',
    activeRepairs: 2,
    completedRepairs: 180,
    avgRepairTime: '2.5 days',
    rating: 4.9,
    status: 'Available',
  },
  {
    id: 'TECH003',
    name: 'James Wilson',
    specialty: 'General Repairs',
    experience: '4 years',
    activeRepairs: 0,
    completedRepairs: 210,
    avgRepairTime: '3.0 days',
    rating: 4.7,
    status: 'Off Duty',
  },
  {
    id: 'TECH004',
    name: 'Sarah Martinez',
    specialty: 'Deep Cleaning',
    experience: '2 years',
    activeRepairs: 4,
    completedRepairs: 150,
    avgRepairTime: '2.2 days',
    rating: 4.6,
    status: 'Busy',
  },
]

export const _RepairTechniciansPage: React.FC = (): JSX.Element => {
  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Repair Technicians</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            <Download size={16} className="inline mr-2" />
            Export List
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            <UserPlus size={16} className="inline mr-2" />
            Add Technician
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Total Technicians
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              12
            </Text>
            <Text className="text-neutral-400">Active Team Members</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+2 vs Last Month</Text>
            <Text>Target: 15</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Currently Active
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              8
            </Text>
            <Text className="text-neutral-400">On Duty Today</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>66% of Team</Text>
            <Text>Target: 75%</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Team Performance
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              4.7
            </Text>
            <Text className="text-neutral-400">Average Rating</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+0.2 vs Last Month</Text>
            <Text>Target: 4.8</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Active Technicians
          </Text>
          <div className="space-y-4">
            {mockTechnicians
              .filter((tech) => tech.status === 'Busy')
              .map((tech) => (
                <div
                  key={tech.id}
                  className="flex justify-between items-center p-3 bg-neutral-700 rounded-lg"
                >
                  <div>
                    <Text variant="label">{tech.name}</Text>
                    <Text variant="body" className="text-neutral-400">
                      {tech.specialty}
                    </Text>
                  </div>
                  <div className="text-right">
                    <Text variant="label">{tech.activeRepairs} Active</Text>
                    <Text variant="body" className="text-neutral-400">
                      {tech.avgRepairTime}
                    </Text>
                  </div>
                </div>
              ))}
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Available Technicians
          </Text>
          <div className="space-y-4">
            {mockTechnicians
              .filter((tech) => tech.status === 'Available')
              .map((tech) => (
                <div
                  key={tech.id}
                  className="flex justify-between items-center p-3 bg-neutral-700 rounded-lg"
                >
                  <div>
                    <Text variant="label">{tech.name}</Text>
                    <Text variant="body" className="text-neutral-400">
                      {tech.specialty}
                    </Text>
                  </div>
                  <div className="text-right">
                    <Text variant="label">⭐ {tech.rating}</Text>
                    <Text variant="body" className="text-neutral-400">
                      {tech.completedRepairs} Completed
                    </Text>
                  </div>
                </div>
              ))}
          </div>
        </Card>
      </Grid>

      <Card className="bg-neutral-800 p-4 rounded-lg">
        <Text variant="h3" className="mb-4">
          All Technicians
        </Text>
        <table className="w-full text-sm">
          <thead className="border-b border-neutral-700">
            <tr>
              <th className="py-3 text-left px-4">NAME</th>
              <th className="py-3 text-left px-4">SPECIALTY</th>
              <th className="py-3 text-left px-4">EXPERIENCE</th>
              <th className="py-3 text-left px-4">ACTIVE REPAIRS</th>
              <th className="py-3 text-left px-4">COMPLETED</th>
              <th className="py-3 text-left px-4">AVG TIME</th>
              <th className="py-3 text-left px-4">RATING</th>
              <th className="py-3 text-left px-4">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {mockTechnicians.map((tech) => (
              <tr
                key={tech.id}
                className="border-b border-neutral-700 hover:bg-neutral-700/50"
              >
                <td className="py-3 px-4 font-medium">{tech.name}</td>
                <td className="py-3 px-4">{tech.specialty}</td>
                <td className="py-3 px-4">{tech.experience}</td>
                <td className="py-3 px-4">{tech.activeRepairs}</td>
                <td className="py-3 px-4">{tech.completedRepairs}</td>
                <td className="py-3 px-4">{tech.avgRepairTime}</td>
                <td className="py-3 px-4">⭐ {tech.rating}</td>
                <td className="py-3 px-4">
                  <span
                    className={`inline-block py-1 px-2 rounded ${
                      tech.status === 'Available'
                        ? 'bg-green-400/20 text-green-400'
                        : tech.status === 'Busy'
                        ? 'bg-yellow-400/20 text-yellow-400'
                        : 'bg-neutral-400/20 text-neutral-400'
                    }`}
                  >
                    {tech.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  )
}
