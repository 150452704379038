import React from 'react'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'

export const _ResaleFinancialsPage: React.FC = (): JSX.Element => {
  const mockRevenueData = [
    { month: 'Jan', revenue: 45000, profit: 15000 },
    { month: 'Feb', revenue: 42000, profit: 14000 },
    { month: 'Mar', revenue: 51000, profit: 17000 },
    { month: 'Apr', revenue: 48000, profit: 16000 },
    { month: 'May', revenue: 56000, profit: 19000 },
    { month: 'Jun', revenue: 59000, profit: 20000 },
  ]

  const mockCategoryData = [
    { label: 'Shoes', value: 28000 },
    { label: 'Apparel', value: 18000 },
    { label: 'Accessories', value: 9000 },
    { label: 'Equipment', value: 4000 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Financial Performance</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            Export Report
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            View Details
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Total Revenue
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              $301,000
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+12.3% vs Last Month</Text>
            <Text>Target: $350,000</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Gross Profit
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              $101,000
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+8.5% vs Last Month</Text>
            <Text>Target: $120,000</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Profit Margin
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              33.6%
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>-1.2% vs Last Month</Text>
            <Text>Target: 35%</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Revenue & Profit Trend
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockRevenueData}
              dataKeys={['revenue', 'profit']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Revenue by Category
          </Text>
          <div className="w-full h-64">
            <BarChart
              data={mockCategoryData}
              className="w-full"
              height={256}
              yLabel="Revenue ($)"
            />
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Cost Breakdown
          </Text>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Processing & Handling</Text>
                <Text variant="body">$45,000 (32%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-green-400 h-full rounded-full"
                  style={{ width: '32%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Marketing & Sales</Text>
                <Text variant="body">$35,000 (25%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-blue-400 h-full rounded-full"
                  style={{ width: '25%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Logistics</Text>
                <Text variant="body">$30,000 (21%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-yellow-400 h-full rounded-full"
                  style={{ width: '21%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Other</Text>
                <Text variant="body">$11,000 (8%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-red-400 h-full rounded-full"
                  style={{ width: '8%' }}
                ></div>
              </div>
            </div>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Top Revenue Sources
          </Text>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Online Store</Text>
                <Text variant="body">$180,000 (60%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-green-400 h-full rounded-full"
                  style={{ width: '60%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Marketplace</Text>
                <Text variant="body">$90,000 (30%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-blue-400 h-full rounded-full"
                  style={{ width: '30%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Retail Partners</Text>
                <Text variant="body">$31,000 (10%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-yellow-400 h-full rounded-full"
                  style={{ width: '10%' }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
