import React from 'react';
import { Card } from '@/shared/components/ui/card';
import { Grid } from '@/shared/components/ui/grid';
import { Text } from '@/shared/components/ui/text';
import { LineChart } from '@/shared/components/ui/charts';
export const _RecycleEnvironmentPage: React.FC = (): void => {
    const carbonData = React.useMemo((): void => {
        return [
            { timestamp: Date.now() - 6 * 24 * 60 * 60 * 1000, value: 1200, label: 'Carbon' },
            { timestamp: Date.now() - 5 * 24 * 60 * 60 * 1000, value: 1350, label: 'Carbon' },
            { timestamp: Date.now() - 4 * 24 * 60 * 60 * 1000, value: 1180, label: 'Carbon' },
            { timestamp: Date.now() - 3 * 24 * 60 * 60 * 1000, value: 1420, label: 'Carbon' },
            { timestamp: Date.now() - 2 * 24 * 60 * 60 * 1000, value: 1380, label: 'Carbon' },
            { timestamp: Date.now() - 1 * 24 * 60 * 60 * 1000, value: 1520, label: 'Carbon' },
            { timestamp: Date.now(), value: 1650, label: 'Carbon' },
        ];
    }, []);
    return (<div className='space-y-8'>
      <h2 className='text-xl font-semibold'>Environment</h2>

      <Grid columns={3} gap={6}>
        <Card className='bg-neutral-800 p-4 rounded-lg'>
          <Text variant='h3' className='mb-2'>
            Carbon Footprint Reduction
          </Text>
          <div className='text-center my-4'>
            <Text variant='h2' className='text-3xl font-bold text-green-400'>
              15.6
            </Text>
            <Text className='text-neutral-400'>Tons CO2e Saved</Text>
          </div>
          <div className='text-sm text-neutral-400 flex justify-between'>
            <Text>+8% vs Last Month</Text>
            <Text>Target: 20 Tons</Text>
          </div>
        </Card>

        <Card className='bg-neutral-800 p-4 rounded-lg'>
          <Text variant='h3' className='mb-2'>
            Water Conservation
          </Text>
          <div className='text-center my-4'>
            <Text variant='h2' className='text-3xl font-bold text-blue-400'>
              45,678
            </Text>
            <Text className='text-neutral-400'>Liters Saved</Text>
          </div>
          <div className='text-sm text-neutral-400 flex justify-between'>
            <Text>+12% vs Last Month</Text>
            <Text>Target: 50,000 L</Text>
          </div>
        </Card>

        <Card className='bg-neutral-800 p-4 rounded-lg'>
          <Text variant='h3' className='mb-2'>
            Energy Efficiency
          </Text>
          <div className='text-center my-4'>
            <Text variant='h2' className='text-3xl font-bold text-yellow-400'>
              92.5%
            </Text>
            <Text className='text-neutral-400'>Efficiency Rate</Text>
          </div>
          <div className='text-sm text-neutral-400 flex justify-between'>
            <Text>+1.5% vs Last Month</Text>
            <Text>Target: 95%</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className='bg-neutral-800 p-4 rounded-lg'>
          <Text variant='h3' className='mb-4'>
            Carbon Savings Trend
          </Text>
          <div className='w-full h-64'>
            <LineChart data={carbonData} height={256} yLabel='CO2e (kg)'/>
          </div>
        </Card>

        <Card className='bg-neutral-800 p-4 rounded-lg'>
          <Text variant='h3' className='mb-4'>
            Environmental Impact
          </Text>
          <div className='space-y-6'>
            <div>
              <div className='flex justify-between items-center mb-2'>
                <Text>Landfill Diversion Rate</Text>
                <Text className='text-green-400'>94.8%</Text>
              </div>
              <div className='w-full bg-neutral-700 h-2 rounded-full'>
                <div className='bg-green-400 h-full rounded-full' style={{ width: '94.8%' }}></div>
              </div>
            </div>

            <div>
              <div className='flex justify-between items-center mb-2'>
                <Text>Renewable Energy Usage</Text>
                <Text className='text-blue-400'>78.2%</Text>
              </div>
              <div className='w-full bg-neutral-700 h-2 rounded-full'>
                <div className='bg-blue-400 h-full rounded-full' style={{ width: '78.2%' }}></div>
              </div>
            </div>

            <div>
              <Text variant='h3' className='mb-3'>
                Material Recovery
              </Text>
              <div className='grid grid-cols-2 gap-4'>
                <div className='bg-neutral-700 p-3 rounded'>
                  <Text className='text-lg font-semibold text-green-400'>98.2%</Text>
                  <Text className='text-sm text-neutral-400'>Plastics</Text>
                </div>
                <div className='bg-neutral-700 p-3 rounded'>
                  <Text className='text-lg font-semibold text-blue-400'>95.8%</Text>
                  <Text className='text-sm text-neutral-400'>Glass</Text>
                </div>
                <div className='bg-neutral-700 p-3 rounded'>
                  <Text className='text-lg font-semibold text-yellow-400'>92.4%</Text>
                  <Text className='text-sm text-neutral-400'>Metal</Text>
                </div>
                <div className='bg-neutral-700 p-3 rounded'>
                  <Text className='text-lg font-semibold text-red-400'>89.6%</Text>
                  <Text className='text-sm text-neutral-400'>Other</Text>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </div>);
};
