import { EventMap } from '../types/events'
export interface EventPayloadMap {
  [_key: string]: unknown
}
export class EventEmitter<T extends EventPayloadMap = EventPayloadMap> {
  private listeners: Map<keyof T, Set<(_payload?: T[keyof T]) => void>> =
    new Map()
  emit<K extends keyof T>(type: K, payload?: T[K]): void {
    const handlers = this.listeners.get(type)
    if (handlers) {
      handlers.forEach((handler): void => handler(payload))
    }
  }
  on<K extends keyof T>(
    type: K,
    handler: (_payload?: T[K]) => void
  ): () => void {
    let handlers = this.listeners.get(type)
    if (!handlers) {
      handlers = new Set()
      this.listeners.set(type, handlers)
    }
    handlers.add(handler as (_payload?: T[keyof T]) => void)
    return (): void => {
      const handlers = this.listeners.get(type)
      if (handlers) {
        handlers.delete(handler as (_payload?: T[keyof T]) => void)
      }
    }
  }
  off<K extends keyof T>(type: K, handler: (_payload?: T[K]) => void): void {
    const handlers = this.listeners.get(type)
    if (handlers) {
      handlers.delete(handler as (_payload?: T[keyof T]) => void)
    }
  }
}
export class TypedEventEmitter<T extends EventMap> {
  private _listenerMap: Map<keyof T, Set<(_event: T[keyof T]) => void>> =
    new Map()
  on<K extends keyof T>(event: K, listener: (_event: T[K]) => void): void {
    if (!this._listenerMap.has(event)) {
      this._listenerMap.set(event, new Set())
    }
    this._listenerMap.get(event)?.add(listener as (_event: T[keyof T]) => void)
  }
  off<K extends keyof T>(event: K, listener: (_event: T[K]) => void): void {
    this._listenerMap
      .get(event)
      ?.delete(listener as (_event: T[keyof T]) => void)
  }
  emit<K extends keyof T>(event: K, data: T[K]): void {
    this._listenerMap.get(event)?.forEach((listener): void => {
      listener(data)
    })
  }
  get listeners(): Map<keyof T, Set<(_event: T[keyof T]) => void>> {
    return this._listenerMap
  }
}
// Create and export a default event emitter instance
export const _eventEmitter = new EventEmitter()
