import React from 'react';
import { NavLink } from 'react-router-dom';
import { Share2, Download, Filter } from 'lucide-react';
import { Text } from '@/shared/components/ui/text';
import { Button } from '@/shared/components/ui/button';
import { Card } from '@/shared/components/ui/card';
interface ResaleItem {
    id: string;
    facility: string;
    dateReceived: string;
    status: string;
    resaleValue: number;
}
const mockResaleItems: ResaleItem[] = [
    {
        id: 'LRPLY00545897',
        facility: 'USCA005\nCalifornia, United States',
        dateReceived: '10 Oct 23 at 10:43',
        status: 'Listed',
        resaleValue: 43,
    },
    {
        id: 'LRPLY00354861',
        facility: 'CAON015\nOntario, United States',
        dateReceived: '10 Oct 23 at 10:42',
        status: 'Listed',
        resaleValue: 50,
    },
];
export const _ResaleInformationPage: React.FC = (): void => {
    return (<div>
      <header className='flex items-center justify-between mb-6'>
        <Text variant='h3'>Resale Information</Text>
        <div className='flex gap-2'>
          <Button variant='outline' size='sm'>
            <Share2 size={16} className='mr-2'/>
            Share
          </Button>
          <Button variant='outline' size='sm'>
            <Download size={16} className='mr-2'/>
            Download
          </Button>
          <Button variant='outline' size='sm'>
            <Filter size={16} className='mr-2'/>
            Filters
          </Button>
        </div>
      </header>

      <Card>
        <table className='w-full text-sm text-neutral-200'>
          <thead className='border-b border-neutral-700 text-neutral-400'>
            <tr>
              <th className='py-3 text-left px-4'>ITEM ID</th>
              <th className='py-3 text-left px-4'>FACILITY</th>
              <th className='py-3 text-left px-4'>DATE RECEIVED</th>
              <th className='py-3 text-left px-4'>STATUS</th>
              <th className='py-3 text-left px-4'>RESALE VALUE ($)</th>
              <th className='py-3 px-4'></th>
            </tr>
          </thead>
          <tbody>
            {mockResaleItems.map((item, i): void => (<tr key={i} className='border-b border-neutral-700 hover:bg-neutral-700/50 transition-colors'>
                <td className='py-3 px-4'>{item.id}</td>
                <td className='py-3 px-4 whitespace-pre'>{item.facility}</td>
                <td className='py-3 px-4'>{item.dateReceived}</td>
                <td className='py-3 px-4'>
                  <span className='bg-neutral-700 text-neutral-200 py-1 px-2 rounded'>
                    {item.status}
                  </span>
                </td>
                <td className='py-3 px-4'>{item.resaleValue}</td>
                <td className='py-3 px-4 text-right'>
                  <NavLink to={`/admin/resale/resale-information/${item.id}`} className='text-neutral-400 hover:text-neutral-100 flex items-center gap-1 justify-end'>
                    Details
                  </NavLink>
                </td>
              </tr>))}
          </tbody>
        </table>
      </Card>

      <div className='flex items-center justify-between mt-4 text-neutral-300'>
        <div>Page 1 of 13</div>
        <div className='flex items-center gap-2'>
          <Button variant='outline' size='sm'>
            &lt;
          </Button>
          <Button variant='outline' size='sm'>
            &gt;
          </Button>
        </div>
      </div>
    </div>);
};
