import React from 'react'
import { cn } from '@/shared/utils/cn'

type TextElement = HTMLHeadingElement | HTMLParagraphElement | HTMLDivElement

interface TextProps
  extends Omit<React.HTMLAttributes<TextElement>, 'className'> {
  children: React.ReactNode
  variant?: 'h1' | 'h2' | 'h3' | 'body' | 'label'
  className?: string
}

export function Text({
  children,
  variant = 'body',
  className,
  ...props
}: TextProps): React.ReactNode {
  const variantStyles = {
    h1: 'text-2xl font-bold',
    h2: 'text-xl font-semibold',
    h3: 'text-lg font-medium',
    body: 'text-base',
    label: 'text-sm font-medium text-neutral-600',
  }

  const Component = variant.startsWith('h') ? variant : 'div'

  return (
    <Component className={cn(variantStyles[variant], className)} {...props}>
      {children}
    </Component>
  )
}
