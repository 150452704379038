import React from 'react'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { Grid } from '@/shared/components/ui/grid'

export const _RefillPackagingFlowsPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    { month: 'January', inbound: 320, outbound: 280 },
    { month: 'February', inbound: 380, outbound: 350 },
    { month: 'March', inbound: 420, outbound: 400 },
    { month: 'April', inbound: 450, outbound: 430 },
    { month: 'May', inbound: 480, outbound: 460 },
    { month: 'June', inbound: 520, outbound: 490 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Packaging Flows</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            Export Data
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            New Flow
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Inbound Volume
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              520
            </Text>
            <Text className="text-neutral-400">Units/Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+8.3% vs Last Month</Text>
            <Text>Target: 600</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Outbound Volume
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              490
            </Text>
            <Text className="text-neutral-400">Units/Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+6.5% vs Last Month</Text>
            <Text>Target: 550</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Processing Efficiency
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              94.2%
            </Text>
            <Text className="text-neutral-400">Current Rate</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+1.2% vs Last Month</Text>
            <Text>Target: 95%</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Volume Trends
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockTimelineData}
              dataKeys={['inbound', 'outbound']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Processing Status
          </Text>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Quality Control</Text>
                <Text variant="body">85% Complete</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-green-400 h-full rounded-full"
                  style={{ width: '85%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Cleaning</Text>
                <Text variant="body">92% Complete</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-blue-400 h-full rounded-full"
                  style={{ width: '92%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Sanitization</Text>
                <Text variant="body">78% Complete</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-yellow-400 h-full rounded-full"
                  style={{ width: '78%' }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Active Flows
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Flow A</Text>
                <Text variant="body">Glass Container Processing</Text>
              </div>
              <Text variant="body" className="text-green-400">
                180 units/day
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Flow B</Text>
                <Text variant="body">Plastic Container Processing</Text>
              </div>
              <Text variant="body" className="text-blue-400">
                150 units/day
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Flow C</Text>
                <Text variant="body">Metal Container Processing</Text>
              </div>
              <Text variant="body" className="text-yellow-400">
                90 units/day
              </Text>
            </div>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Facility Performance
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Text variant="body">California Facility</Text>
              <Text variant="body" className="text-green-400">
                98% efficiency
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">New York Facility</Text>
              <Text variant="body" className="text-blue-400">
                95% efficiency
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Texas Facility</Text>
              <Text variant="body" className="text-yellow-400">
                92% efficiency
              </Text>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
