import React from 'react'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { Grid } from '@/shared/components/ui/grid'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'

export const _RefillOverviewPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    { month: 'January', returns: 120, processed: 110 },
    { month: 'February', returns: 150, processed: 140 },
    { month: 'March', returns: 180, processed: 170 },
    { month: 'April', returns: 200, processed: 190 },
    { month: 'May', returns: 220, processed: 210 },
    { month: 'June', returns: 250, processed: 240 },
  ]

  const mockCompositionData = [
    { label: 'Glass', value: 450 },
    { label: 'Plastic', value: 320 },
    { label: 'Metal', value: 180 },
    { label: 'Other', value: 50 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Refill Overview</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            Export Data
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            New Program
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Total Returns
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              1,120
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+12.5% vs Last Month</Text>
            <Text>Target: 1,500</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Processing Rate
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              94.6%
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+2.1% vs Last Month</Text>
            <Text>Target: 98%</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Average Processing Time
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              2.3
            </Text>
            <Text className="text-neutral-400">Days</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+0.2 days vs Last Month</Text>
            <Text>Target: 2.0 days</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Returns Timeline
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockTimelineData}
              dataKeys={['returns', 'processed']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Return Composition
          </Text>
          <div className="w-full h-64">
            <BarChart
              data={mockCompositionData}
              className="w-full"
              height={256}
              yLabel="Count"
            />
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Recent Activity
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Program A</Text>
                <Text variant="body">25 returns processed</Text>
              </div>
              <Text variant="body" className="text-neutral-400">
                2 hours ago
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Program B</Text>
                <Text variant="body">18 returns received</Text>
              </div>
              <Text variant="body" className="text-neutral-400">
                4 hours ago
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Program C</Text>
                <Text variant="body">32 returns completed</Text>
              </div>
              <Text variant="body" className="text-neutral-400">
                6 hours ago
              </Text>
            </div>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Active Programs
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Text variant="body">California Refill Center</Text>
              <Text variant="body" className="text-green-400">
                450 returns
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">New York Distribution</Text>
              <Text variant="body" className="text-blue-400">
                320 returns
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Texas Processing Hub</Text>
              <Text variant="body" className="text-yellow-400">
                180 returns
              </Text>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
