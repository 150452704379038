import React, { useState, useCallback } from 'react'
import { X, Check, ChevronDown } from 'lucide-react'

interface Option {
  label: string
  value: string
}

interface MultiSelectProps {
  options: Option[]
  value: string[]
  onChange: (value: string[]) => void
  className?: string
  placeholder?: string
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  value,
  onChange,
  className = '',
  placeholder = 'Select options...',
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  )

  const toggleOption = useCallback(
    (optionValue: string) => {
      const newValue = value.includes(optionValue)
        ? value.filter((v) => v !== optionValue)
        : [...value, optionValue]
      onChange(newValue)
    },
    [value, onChange]
  )

  const removeOption = useCallback(
    (e: React.MouseEvent, optionValue: string) => {
      e.stopPropagation()
      onChange(value.filter((v) => v !== optionValue))
    },
    [value, onChange]
  )

  return (
    <div className="relative">
      <div
        className={`min-h-[42px] px-3 py-2 bg-neutral-800 border border-neutral-700 rounded-lg cursor-pointer ${className}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-wrap gap-2">
          {value.length > 0 ? (
            value.map((v) => {
              const option = options.find((o) => o.value === v)
              return (
                <span
                  key={v}
                  className="inline-flex items-center px-2 py-1 bg-neutral-700 rounded-md text-sm text-white"
                >
                  {option?.label}
                  <button
                    onClick={(e) => removeOption(e, v)}
                    className="ml-1 text-neutral-400 hover:text-white"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </span>
              )
            })
          ) : (
            <span className="text-neutral-400">{placeholder}</span>
          )}
        </div>
        <div className="absolute right-3 top-1/2 -translate-y-1/2">
          <ChevronDown
            className={`w-4 h-4 text-neutral-400 transition-transform ${
              isOpen ? 'rotate-180' : ''
            }`}
          />
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-neutral-800 border border-neutral-700 rounded-lg shadow-lg">
          <div className="p-2">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full px-3 py-2 bg-neutral-700 border border-neutral-600 rounded-md text-white placeholder-neutral-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Search options..."
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className="max-h-60 overflow-y-auto">
            {filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`px-3 py-2 flex items-center justify-between cursor-pointer hover:bg-neutral-700 ${
                  value.includes(option.value) ? 'bg-neutral-700' : ''
                }`}
                onClick={() => toggleOption(option.value)}
              >
                <span className="text-white">{option.label}</span>
                {value.includes(option.value) && (
                  <Check className="w-4 h-4 text-purple-500" />
                )}
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className="px-3 py-2 text-neutral-400 text-center">
                No options found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
