import React from 'react'
import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

interface LineChartProps {
  data: any[]
  dataKeys: string[]
  xAxisKey: string
  height?: number
  colors?: string[]
  className?: string
}

export const _LineChart: React.FC<LineChartProps> = ({
  data,
  dataKeys,
  xAxisKey,
  height = 300,
  colors,
  className,
}): JSX.Element => {
  return (
    <div className={className} style={{ width: '100%', height }}>
      <ResponsiveContainer>
        <RechartsLineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
          <XAxis
            dataKey={xAxisKey}
            stroke="#9CA3AF"
            style={{ fontSize: '12px' }}
          />
          <YAxis stroke="#9CA3AF" style={{ fontSize: '12px' }} />
          <Tooltip
            contentStyle={{
              backgroundColor: '#1F2937',
              border: '1px solid #374151',
              borderRadius: '6px',
              color: '#F3F4F6',
            }}
          />
          <Legend
            wrapperStyle={{
              color: '#9CA3AF',
              fontSize: '12px',
            }}
          />
          {dataKeys.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={colors?.[index] || `hsl(${index * 45}, 70%, 50%)`}
              strokeWidth={2}
              dot={{
                fill: colors?.[index] || `hsl(${index * 45}, 70%, 50%)`,
                strokeWidth: 2,
              }}
              activeDot={{ r: 6 }}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  )
}
