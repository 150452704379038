import React from 'react'
import { cn } from '@/lib/utils'

interface KioskButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
}

export default function KioskButton({
  children,
  className,
  ...props
}: KioskButtonProps): JSX.Element {
  return (
    <div
      {...props}
      className={cn(
        'border border-white rounded-full flex gap-3 py-2 px-5 items-center cursor-pointer hover:bg-white/10 transition-all duration-300',
        className
      )}
    >
      {children}
    </div>
  )
}
