import { graphql, HttpResponse } from 'msw'
import {
  _mockProcessedItems as mockProcessedItems,
  _mockMetrics as mockMetrics,
} from '../dashboard/mock-data'
export const _handlers = [
  graphql.query('GetDashboardMetrics', (): void => {
    return HttpResponse.json({
      data: {
        dashboardMetrics: {
          processedItems: mockProcessedItems,
          metrics: mockMetrics,
        },
      },
    })
  }),
  graphql.query('GetProcessedItems', (): void => {
    return HttpResponse.json({
      data: {
        processedItems: mockProcessedItems,
      },
    })
  }),
  graphql.query('GetMetricOverviews', (): void => {
    return HttpResponse.json({
      data: {
        metricOverviews: mockMetrics,
      },
    })
  }),
]
