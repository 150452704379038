import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
export const _RefillProgramPage: React.FC = (): void => {
    const _location = useLocation();
    const tabs = [
        { path: '/admin/refills/overview', label: 'Overview' },
        { path: '/admin/refills/packaging-flows', label: 'Packaging Flows' },
        { path: '/admin/refills/return-information', label: 'Return Information' },
    ];
    return (<div className='py-6'>
      <div className='flex justify-between items-center mb-6'>
        <h1 className='text-2xl font-semibold text-text-primary'>Refill Program</h1>
        <div className='flex gap-3'>
          <button className='px-4 py-2 bg-bg-panel text-text-primary rounded hover:bg-bg-hover'>
            Export Data
          </button>
          <button className='px-4 py-2 bg-accent-green text-text-primary rounded hover:opacity-90'>
            New Program
          </button>
        </div>
      </div>

      <nav className='tabs-list mb-6'>
        {tabs.map((tab): void => (<NavLink key={tab.path} to={tab.path} className={({ isActive }): void => `tab-trigger ${isActive ? 'border-b-2 border-accent-purple text-text-primary' : ''}`}>
            {tab.label}
          </NavLink>))}
      </nav>

      <div className='card'>
        <Outlet />
      </div>
    </div>);
};
