import { createContext, useContext } from 'react';
import { VoiceCommand } from '../types';
import { VoiceProvider } from './voice-provider';
export interface VoiceContextType {
    isListening: boolean;
    isLoading: boolean;
    transcript: string;
    confidence: number;
    lastCommand?: VoiceCommand;
    error?: Error;
    startListening: () => Promise<void>;
    stopListening: () => void;
    clearError: () => void;
    clearTranscript: () => void;
    clearLastCommand: () => void;
    reset: () => void;
}
const VoiceContext = createContext<VoiceContextType | null>(null);
export function useVoiceContext(): VoiceContextType {
    const context = useContext(VoiceContext);
    if (!context) {
        throw new Error('useVoiceContext must be used within a VoiceProvider');
    }
    return context;
}
export { VoiceContext, VoiceProvider };
