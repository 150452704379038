import React from 'react'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'
import { Download, Filter, Share2 } from 'lucide-react'
import { LearningStream } from '@/domain/ai/components/learning/learning-stream'
import { _learningSystem } from '@/domain/ai/learning'

export const _AILearningPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    { month: 'Jan', accuracy: 85, latency: 120 },
    { month: 'Feb', accuracy: 87, latency: 115 },
    { month: 'Mar', accuracy: 89, latency: 110 },
    { month: 'Apr', accuracy: 92, latency: 105 },
    { month: 'May', accuracy: 94, latency: 100 },
    { month: 'Jun', accuracy: 95, latency: 95 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">AI Learning Overview</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            <Share2 size={16} className="inline mr-2" />
            Share
          </button>
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            <Download size={16} className="inline mr-2" />
            Export
          </button>
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            <Filter size={16} className="inline mr-2" />
            Filter
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Pattern Recognition Rate
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              95.2%
            </Text>
            <Text className="text-neutral-400">Recognition Accuracy</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+2.3% vs Last Month</Text>
            <Text>Target: 97%</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Response Time
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              95ms
            </Text>
            <Text className="text-neutral-400">Average Latency</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>-5ms vs Last Month</Text>
            <Text>Target: 90ms</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Success Rate
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              98.5%
            </Text>
            <Text className="text-neutral-400">Task Completion</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+0.5% vs Last Month</Text>
            <Text>Target: 99%</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Performance Metrics
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockTimelineData}
              dataKeys={['accuracy', 'latency']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Learning Stream
          </Text>
          <div className="h-64 overflow-auto">
            <LearningStream
              learningSystem={_learningSystem}
              maxEvents={5}
              refreshInterval={5000}
            />
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Top Learning Patterns
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Voice Command Processing</Text>
                <Text variant="body" className="text-neutral-400">
                  99.2% accuracy
                </Text>
              </div>
              <Text variant="body" className="text-green-400">
                High Confidence
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Product Recommendations</Text>
                <Text variant="body" className="text-neutral-400">
                  98.7% accuracy
                </Text>
              </div>
              <Text variant="body" className="text-green-400">
                High Confidence
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Customer Intent Analysis</Text>
                <Text variant="body" className="text-neutral-400">
                  97.5% accuracy
                </Text>
              </div>
              <Text variant="body" className="text-yellow-400">
                Medium Confidence
              </Text>
            </div>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            System Health
          </Text>
          <div className="space-y-6">
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">Memory Usage</Text>
                <Text variant="label">85%</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-blue-400 h-2 rounded-full"
                  style={{ width: '85%' }}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">CPU Load</Text>
                <Text variant="label">65%</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-green-400 h-2 rounded-full"
                  style={{ width: '65%' }}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">Storage</Text>
                <Text variant="label">45%</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-yellow-400 h-2 rounded-full"
                  style={{ width: '45%' }}
                />
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
