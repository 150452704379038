import { v4 as uuidv4 } from 'uuid'
import type {
  VoiceCommand,
  VoiceFlowHandler,
  VoiceProcessingResult,
  VoiceProcessingError,
  VoiceProcessingMetrics,
  VoiceProcessorConfig,
  VoiceCommandEvent,
} from './types'
import type {
  ConversationState,
  ConversationResponse,
  AIAction,
} from '../../retail/use-cases/conversations/types'
import { VoiceEventBus } from './events'
import { CommandHistory } from './command-history'
import { RateLimiter } from './rate-limiter'
import type {
  SpeechRecognition,
  SpeechRecognitionEvent,
  SpeechRecognitionErrorEvent,
  SpeechRecognitionResult,
} from '../../../shared/types/web-speech'

interface CommandEntry {
  command: VoiceCommand
  result: VoiceProcessingResult
}

export class VoiceProcessor {
  private currentState: ConversationState
  private flowHandlers: VoiceFlowHandler[]
  private eventBus: VoiceEventBus
  private rateLimiter: RateLimiter
  private history: CommandHistory
  private recognition: SpeechRecognition | null = null

  constructor(
    initialState: ConversationState,
    handlers: VoiceFlowHandler[],
    eventBus: VoiceEventBus,
    config: VoiceProcessorConfig = {}
  ) {
    this.currentState = initialState
    this.flowHandlers = handlers
    this.eventBus = eventBus
    this.rateLimiter = new RateLimiter(
      config.maxRequestsPerInterval,
      config.intervalMs,
      config.burstLimit
    )
    this.history = new CommandHistory(
      config.maxHistoryEntries,
      config.historyRetentionPeriod
    )
  }

  async start(): Promise<void> {
    if (!this.recognition) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition
      this.recognition = new SpeechRecognition()
      this.recognition.continuous = true
      this.recognition.interimResults = false
      this.recognition.maxAlternatives = 1
      this.recognition.lang = 'en-US'

      this.recognition.onstart = () => {
        this.eventBus.emit('VOICE_PROCESSING', {
          id: uuidv4(),
          text: 'Voice recognition started',
          type: 'PRESENCE_CHECK',
          confidence: 1,
          timestamp: Date.now(),
        })
      }

      this.recognition.onend = () => {
        this.eventBus.emit('VOICE_PROCESSING', {
          id: uuidv4(),
          text: 'Voice recognition ended',
          type: 'PRESENCE_CHECK',
          confidence: 1,
          timestamp: Date.now(),
        })
      }

      this.recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
        const error: VoiceProcessingError = {
          id: uuidv4(),
          error: event.error,
          command: {
            id: uuidv4(),
            text: '',
            type: 'ERROR',
            confidence: 0,
            timestamp: Date.now(),
          },
          timestamp: Date.now(),
        }
        this.eventBus.emit('VOICE_ERROR', error)
      }

      this.recognition.onresult = async (event: SpeechRecognitionEvent) => {
        const result = event.results[event.resultIndex]
        if (result.isFinal) {
          const transcript = result[0].transcript.trim()
          const confidence = result[0].confidence

          if (!this.rateLimiter.canMakeRequest()) {
            this.eventBus.emit('VOICE_ERROR', {
              id: uuidv4(),
              error: 'Rate limit exceeded',
              command: {
                id: uuidv4(),
                text: transcript,
                type: 'ERROR',
                confidence,
                timestamp: Date.now(),
              },
              timestamp: Date.now(),
            })
            return
          }

          this.rateLimiter.recordRequest()

          const command: VoiceCommand = {
            id: uuidv4(),
            text: transcript,
            type: 'NAVIGATION',
            confidence,
            timestamp: Date.now(),
          }

          try {
            const result = await this.processCommand(command)
            this.history.addEntry(command, result)
            this.eventBus.emit('VOICE_COMMAND', command)
          } catch (error) {
            const errorEvent: VoiceProcessingError = {
              id: uuidv4(),
              error: error instanceof Error ? error.message : 'Unknown error',
              command,
              timestamp: Date.now(),
            }
            this.eventBus.emit('VOICE_ERROR', errorEvent)
          }
        }
      }
    }

    try {
      await this.recognition.start()
    } catch (error) {
      const errorEvent: VoiceProcessingError = {
        id: uuidv4(),
        error:
          error instanceof Error
            ? error.message
            : 'Failed to start recognition',
        command: {
          id: uuidv4(),
          text: '',
          type: 'ERROR',
          confidence: 0,
          timestamp: Date.now(),
        },
        timestamp: Date.now(),
      }
      this.eventBus.emit('VOICE_ERROR', errorEvent)
    }
  }

  stop(): void {
    if (this.recognition) {
      this.recognition.stop()
      this.recognition = null
    }
  }

  cleanup(): void {
    this.stop()
    this.history = new CommandHistory()
  }

  private async handleError(error: VoiceProcessingError): Promise<void> {
    const errorEvent: VoiceCommandEvent = {
      type: 'VOICE_ERROR',
      payload: error,
    }
    this.eventBus.emit(errorEvent.type, errorEvent.payload)
  }

  async processCommand(command: VoiceCommand): Promise<VoiceProcessingResult> {
    for (const handler of this.flowHandlers) {
      if (handler.canHandle(command)) {
        try {
          const result = await handler.handle(command)
          return {
            text: command.text,
            confidence: command.confidence,
            success: true,
            command: {
              text: command.text,
              confidence: command.confidence,
            },
            response: result.text,
            intent: command.type,
            feedback: {
              wasSuccessful: true,
            },
            timestamp: Date.now(),
          }
        } catch (error) {
          const voiceError: VoiceProcessingError = {
            id: uuidv4(),
            error: error instanceof Error ? error.message : 'Handler failed',
            command,
            timestamp: Date.now(),
          }
          await this.handleError(voiceError)
          return {
            text: command.text,
            confidence: command.confidence,
            success: false,
            error: voiceError.error,
            command: {
              text: command.text,
              confidence: command.confidence,
            },
            timestamp: Date.now(),
          }
        }
      }
    }

    const noHandlerError: VoiceProcessingError = {
      id: uuidv4(),
      error: 'No handler found for command',
      command,
      timestamp: Date.now(),
    }
    await this.handleError(noHandlerError)
    return {
      text: command.text,
      confidence: command.confidence,
      success: false,
      error: noHandlerError.error,
      command: {
        text: command.text,
        confidence: command.confidence,
      },
      timestamp: Date.now(),
    }
  }

  getSuccessfulCommands(): VoiceCommand[] {
    return this.history
      .getEntries()
      .filter((entry) => entry.result.success)
      .map((entry) => entry.command)
  }

  getFailedCommands(): VoiceCommand[] {
    return this.history
      .getEntries()
      .filter((entry) => !entry.result.success)
      .map((entry) => entry.command)
  }

  getMetrics(): VoiceProcessingMetrics {
    const entries = this.history.getEntries()
    const total = entries.length
    const successful = entries.filter((entry) => entry.result.success).length
    const confidenceSum = entries.reduce(
      (sum, entry) => sum + entry.command.confidence,
      0
    )
    const latencySum = entries.reduce((sum, entry) => {
      const processTime = entry.result.timestamp || 0
      const commandTime = entry.command.timestamp
      return sum + (processTime - commandTime)
    }, 0)

    return {
      commandsProcessed: total,
      averageConfidence: total > 0 ? confidenceSum / total : 0,
      successRate: total > 0 ? successful / total : 0,
      errorRate: total > 0 ? (total - successful) / total : 0,
      latency: total > 0 ? latencySum / total : 0,
    }
  }

  getCommandsByType(type: VoiceCommand['type']): VoiceCommand[] {
    return this.history
      .getEntries()
      .filter((entry) => entry.command.type === type)
      .map((entry) => entry.command)
  }

  reset(): void {
    this.cleanup()
    this.currentState = {
      path: '/',
      flow: {
        stage: 'welcome',
        context: {},
        history: {
          commands: [],
          responses: [],
          actions: [],
          timestamps: [],
        },
        ui: {
          activeSection: 'welcome',
        },
      },
      customerInfo: {
        name: '',
        membershipStatus: 'none',
        visitCount: 0,
        hasItemInHand: false,
        currentTime: new Date(),
        deviceType: 'unknown',
        preferences: {
          timeOfDay: 'any',
          categories: [],
          sustainabilityFocus: false,
        },
      },
      loyaltyPoints: {
        current: 0,
      },
    }
  }

  async process(command: string): Promise<VoiceProcessingResult> {
    const voiceCommand: VoiceCommand = {
      id: uuidv4(),
      text: command,
      type: 'NAVIGATION',
      confidence: 1,
      timestamp: Date.now(),
    }

    return this.processCommand(voiceCommand)
  }

  async handleSystemFeedback(type: string): Promise<ConversationResponse> {
    const suggestions = ['help', 'greeting', 'presence', 'escalate']
    const data = {
      suggestions,
      timestamp: Date.now(),
    }

    const systemFeedbackAction: AIAction = {
      type: 'SYSTEM_FEEDBACK',
      payload: {
        data,
        metadata: {
          feedbackType: type,
        },
      },
      confidence: 1,
      timestamp: Date.now(),
    }

    let text = ''
    switch (type) {
      case 'help':
        text =
          'How can I assist you today? You can ask me about products, returns, repairs, or refills.'
        break
      case 'greeting':
        text = 'Hello! Welcome to our store. How may I help you today?'
        break
      case 'presence':
        text = "Yes, I'm here and ready to assist you."
        break
      case 'escalate':
        text = "I'll connect you with a human representative right away."
        break
      case 'error':
        text =
          "I apologize, but I'm having trouble understanding. Could you please rephrase that?"
        break
      default:
        text = "I'm here to help. What would you like to do?"
    }

    const response: ConversationResponse = {
      text,
      action: systemFeedbackAction,
      suggestions,
      uiUpdates: {
        loading: false,
      },
    }

    return response
  }
}
