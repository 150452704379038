import React, { useCallback, useState } from 'react'
import { Node, Edge, useNodesState, useEdgesState, Connection } from 'reactflow'
import WorkflowCanvas from './workflow-canvas'
import { Button } from '@/shared/components/ui/button'
import { Trash2, Save, Upload, Play, Pause, FileText } from 'lucide-react'
import { useToast } from '@/shared/components/ui/use-toast'
import { validateWorkflow } from './workflow-validator'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/components/ui/dialog'
import { WorkflowTemplateSelector } from './workflow-template-selector'
import ConfigurationPanel from './configuration/configuration-panel'

interface AgentNodeData {
  label: string
  type: string
  status: string
  version: string
  inputs?: string[]
  outputs?: Record<string, string>
}

export const AgentBuilderPage: React.FC = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState<AgentNodeData>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [selectedNode, setSelectedNode] = useState<Node<AgentNodeData> | null>(
    null
  )
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false)
  const [isSimulationDialogOpen, setIsSimulationDialogOpen] = useState(false)
  const [isSimulationRunning, setIsSimulationRunning] = useState(false)
  const { toast } = useToast()

  const onConnect = useCallback(
    (params: Connection) => {
      // Validate connection before adding
      const sourceNode = nodes.find((n) => n.id === params.source)
      const targetNode = nodes.find((n) => n.id === params.target)

      if (!sourceNode || !targetNode) {
        toast({
          title: 'Invalid Connection',
          description: 'Could not find source or target node',
          variant: 'destructive',
        })
        return
      }

      const sourceOutput =
        sourceNode.data.outputs?.[params.sourceHandle as string]
      const targetInput = params.targetHandle

      if (!sourceOutput || !targetInput) {
        toast({
          title: 'Invalid Connection',
          description: 'Missing input or output type',
          variant: 'destructive',
        })
        return
      }

      setEdges((eds) => eds.concat({ ...params, id: `e${eds.length + 1}` }))
    },
    [nodes, setEdges, toast]
  )

  const handleAddNode = useCallback(
    (component: any) => {
      const position = {
        x: Math.random() * 500,
        y: Math.random() * 500,
      }

      const newNode: Node<AgentNodeData> = {
        id: `node${nodes.length + 1}`,
        type: 'agent',
        position,
        data: {
          label: component.label,
          type: component.type,
          status: 'active',
          version: '1.0',
          inputs: component.inputs,
          outputs: component.outputs,
        },
      }

      setNodes((nds) => nds.concat(newNode))
    },
    [nodes, setNodes]
  )

  const handleClearCanvas = useCallback(() => {
    setNodes([])
    setEdges([])
  }, [setNodes, setEdges])

  const handleSaveWorkflow = useCallback(() => {
    const validation = validateWorkflow(nodes, edges)
    if (!validation.isValid) {
      toast({
        title: 'Invalid Workflow',
        description: validation.errors[0],
        variant: 'destructive',
      })
      return
    }

    const workflow = { nodes, edges }
    const blob = new Blob([JSON.stringify(workflow, null, 2)], {
      type: 'application/json',
    })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'workflow.json'
    a.click()
    URL.revokeObjectURL(url)

    toast({
      title: 'Workflow Saved',
      description: 'Your workflow has been saved successfully',
    })
  }, [nodes, edges, toast])

  const handleLoadWorkflow = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = (e) => {
        try {
          const workflow = JSON.parse(e.target?.result as string)
          const validation = validateWorkflow(workflow.nodes, workflow.edges)
          if (!validation.isValid) {
            toast({
              title: 'Invalid Workflow',
              description: validation.errors[0],
              variant: 'destructive',
            })
            return
          }

          setNodes(workflow.nodes)
          setEdges(workflow.edges)
          toast({
            title: 'Workflow Loaded',
            description: 'Your workflow has been loaded successfully',
          })
        } catch (error) {
          toast({
            title: 'Error Loading Workflow',
            description: 'Failed to parse workflow file',
            variant: 'destructive',
          })
        }
      }
      reader.readAsText(file)
    },
    [setNodes, setEdges, toast]
  )

  const handleTemplateSelect = useCallback(
    (template: any) => {
      setNodes(template.nodes)
      setEdges(template.edges)
      setIsTemplateDialogOpen(false)
      toast({
        title: 'Template Loaded',
        description: 'The workflow template has been loaded successfully.',
      })
    },
    [setNodes, setEdges, toast]
  )

  const handleSimulationToggle = useCallback(() => {
    if (!isSimulationRunning) {
      const validation = validateWorkflow(nodes, edges)
      if (!validation.isValid) {
        toast({
          title: 'Invalid Workflow',
          description: validation.errors[0],
          variant: 'destructive',
        })
        return
      }
      setIsSimulationDialogOpen(true)
    }
    setIsSimulationRunning(!isSimulationRunning)
  }, [isSimulationRunning, nodes, edges, toast])

  const handleNodeClick = useCallback(
    (_: React.MouseEvent, node: Node<AgentNodeData>) => {
      setSelectedNode(node)
    },
    []
  )

  const handleConfigChange = useCallback(
    (nodeId: string, key: string, value: any) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                [key]: value,
              },
            }
          }
          return node
        })
      )
    },
    [setNodes]
  )

  return (
    <div className="flex flex-col h-[100vh]">
      <div className="flex items-center justify-between p-4 border-b border-border">
        <h1 className="text-2xl font-semibold">Agent Builder</h1>
        <div className="flex items-center gap-2">
          <Dialog
            open={isTemplateDialogOpen}
            onOpenChange={setIsTemplateDialogOpen}
          >
            <DialogTrigger asChild>
              <Button variant="outline">
                <FileText className="w-4 h-4 mr-2" />
                Load Template
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
              <DialogHeader>
                <DialogTitle>Load Workflow Template</DialogTitle>
                <DialogDescription>
                  Choose a pre-configured workflow template to get started
                  quickly.
                </DialogDescription>
              </DialogHeader>
              <WorkflowTemplateSelector onSelect={handleTemplateSelect} />
            </DialogContent>
          </Dialog>

          <Button variant="outline" onClick={handleClearCanvas}>
            <Trash2 className="w-4 h-4 mr-2" />
            Clear Canvas
          </Button>

          <Button variant="outline" onClick={handleSaveWorkflow}>
            <Save className="w-4 h-4 mr-2" />
            Save
          </Button>

          <div className="relative">
            <Button
              variant="outline"
              onClick={() => document.getElementById('file-upload')?.click()}
            >
              <Upload className="w-4 h-4 mr-2" />
              Load
            </Button>
            <input
              id="file-upload"
              type="file"
              accept=".json"
              className="hidden"
              onChange={handleLoadWorkflow}
            />
          </div>

          <Dialog
            open={isSimulationDialogOpen}
            onOpenChange={setIsSimulationDialogOpen}
          >
            <DialogTrigger asChild>
              <Button
                variant={isSimulationRunning ? 'secondary' : 'default'}
                onClick={handleSimulationToggle}
              >
                {isSimulationRunning ? (
                  <Pause className="w-4 h-4 mr-2" />
                ) : (
                  <Play className="w-4 h-4 mr-2" />
                )}
                {isSimulationRunning ? 'Stop Simulation' : 'Run Simulation'}
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[800px]">
              <DialogHeader>
                <DialogTitle>Workflow Simulation</DialogTitle>
                <DialogDescription>
                  Monitor the performance and metrics of your workflow in
                  real-time.
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                {/* Simulation metrics and controls will go here */}
                <div className="p-4 border rounded-lg">
                  <h3 className="font-medium mb-2">Simulation Controls</h3>
                  {/* Add simulation controls */}
                </div>
                <div className="p-4 border rounded-lg">
                  <h3 className="font-medium mb-2">Performance Metrics</h3>
                  {/* Add performance metrics */}
                </div>
                <div className="p-4 border rounded-lg">
                  <h3 className="font-medium mb-2">Event Log</h3>
                  {/* Add event log */}
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="relative flex-1 min-h-0 flex">
        <div className="flex-1">
          <WorkflowCanvas
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={handleNodeClick}
            onAddNode={handleAddNode}
          />
        </div>
        {selectedNode && (
          <div className="w-[300px] border-l border-border bg-background overflow-y-auto">
            <ConfigurationPanel
              node={selectedNode}
              onConfigChange={handleConfigChange}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default AgentBuilderPage
