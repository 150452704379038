import React from 'react'
import { NavLink } from 'react-router-dom'
import { Share2, Download, Filter } from 'lucide-react'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'

interface RepairItem {
  id: string
  customer: string
  product: string
  status: string
  dateReceived: string
  estimatedCompletion: string
  technician: string
}

const mockRepairs: RepairItem[] = [
  {
    id: 'REP00545897',
    customer: 'John Smith',
    product: 'Cloudmonster • Size 11',
    status: 'In Progress',
    dateReceived: '10 Oct 23 at 10:43',
    estimatedCompletion: '13 Oct 23',
    technician: 'Mike Johnson',
  },
  {
    id: 'REP00354861',
    customer: 'Sarah Wilson',
    product: 'Cloudflow • Size 8',
    status: 'Completed',
    dateReceived: '10 Oct 23 at 09:15',
    estimatedCompletion: '12 Oct 23',
    technician: 'Lisa Chen',
  },
  {
    id: 'REP00354862',
    customer: 'David Brown',
    product: 'Cloudswift • Size 10',
    status: 'Pending',
    dateReceived: '10 Oct 23 at 08:30',
    estimatedCompletion: '14 Oct 23',
    technician: 'Unassigned',
  },
  {
    id: 'REP00354863',
    customer: 'Emma Davis',
    product: 'Cloudventure • Size 7',
    status: 'In Progress',
    dateReceived: '09 Oct 23 at 16:45',
    estimatedCompletion: '12 Oct 23',
    technician: 'Mike Johnson',
  },
  {
    id: 'REP00354864',
    customer: 'Michael Lee',
    product: 'Cloudstratus • Size 9',
    status: 'Completed',
    dateReceived: '09 Oct 23 at 15:20',
    estimatedCompletion: '11 Oct 23',
    technician: 'Lisa Chen',
  },
]

export const _RepairsListPage: React.FC = (): JSX.Element => {
  return (
    <div className="p-6">
      <header className="flex items-center justify-between mb-6">
        <Text variant="h3">Repairs List</Text>
        <div className="flex gap-2">
          <button className="flex items-center gap-1 bg-gray-100 text-gray-700 py-2 px-3 rounded hover:bg-gray-200">
            <Share2 size={16} />
            Share
          </button>
          <button className="flex items-center gap-1 bg-gray-100 text-gray-700 py-2 px-3 rounded hover:bg-gray-200">
            <Download size={16} />
            Download
          </button>
          <button className="flex items-center gap-1 bg-gray-100 text-gray-700 py-2 px-3 rounded hover:bg-gray-200">
            <Filter size={16} />
            Filters
          </button>
        </div>
      </header>

      <Card>
        <table className="w-full text-sm">
          <thead className="border-b">
            <tr>
              <th className="py-3 text-left px-4">REPAIR ID</th>
              <th className="py-3 text-left px-4">CUSTOMER</th>
              <th className="py-3 text-left px-4">PRODUCT</th>
              <th className="py-3 text-left px-4">STATUS</th>
              <th className="py-3 text-left px-4">RECEIVED</th>
              <th className="py-3 text-left px-4">EST. COMPLETION</th>
              <th className="py-3 text-left px-4">TECHNICIAN</th>
              <th className="py-3 px-4"></th>
            </tr>
          </thead>
          <tbody>
            {mockRepairs.map((repair) => (
              <tr key={repair.id} className="border-b hover:bg-gray-50">
                <td className="py-3 px-4">{repair.id}</td>
                <td className="py-3 px-4">{repair.customer}</td>
                <td className="py-3 px-4">{repair.product}</td>
                <td className="py-3 px-4">
                  <span
                    className={`inline-block py-1 px-2 rounded ${
                      repair.status === 'Completed'
                        ? 'bg-green-100 text-green-800'
                        : repair.status === 'In Progress'
                        ? 'bg-blue-100 text-blue-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}
                  >
                    {repair.status}
                  </span>
                </td>
                <td className="py-3 px-4">{repair.dateReceived}</td>
                <td className="py-3 px-4">{repair.estimatedCompletion}</td>
                <td className="py-3 px-4">{repair.technician}</td>
                <td className="py-3 px-4 text-right">
                  <NavLink
                    to={`/admin/repairs/${repair.id}`}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    Details
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <div className="flex items-center justify-between mt-4 text-sm text-gray-600">
        <div>Page 1 of 5</div>
        <div className="flex items-center gap-2">
          <button className="bg-gray-100 px-3 py-1 rounded hover:bg-gray-200">
            &lt;
          </button>
          <button className="bg-gray-100 px-3 py-1 rounded hover:bg-gray-200">
            &gt;
          </button>
        </div>
      </div>
    </div>
  )
}
