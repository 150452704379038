import * as React from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface BarChartProps {
  data: Array<{ label: string; value: number }>
  className?: string
  height?: number
  yLabel?: string
}

export const BarChart: React.FC<BarChartProps> = ({
  data,
  className,
  height = 300,
  yLabel = '',
}): JSX.Element => {
  const chartData: ChartData<'bar'> = {
    labels: data.map((d) => d.label),
    datasets: [
      {
        label: yLabel,
        data: data.map((d) => d.value),
        backgroundColor: '#8b5cf6',
        borderColor: '#7c3aed',
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#64748b',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(148, 163, 184, 0.1)',
        },
        ticks: {
          color: '#64748b',
        },
      },
    },
    plugins: {
      legend: {
        display: Boolean(yLabel),
        position: 'top' as const,
        labels: {
          color: '#64748b',
        },
      },
    },
  }

  return (
    <div className={className} style={{ height }}>
      <Bar data={chartData} options={options} />
    </div>
  )
}

BarChart.displayName = 'BarChart'
