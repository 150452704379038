export class RateLimiter {
  private requestTimes: number[] = []
  private readonly maxRequests: number
  private readonly intervalMs: number
  private readonly burstLimit: number
  constructor(
    maxRequests: number = 10,
    intervalMs: number = 1000,
    burstLimit: number = 3
  ) {
    this.maxRequests = maxRequests
    this.intervalMs = intervalMs
    this.burstLimit = burstLimit
  }
  canMakeRequest(): boolean {
    const now = Date.now()
    const windowStart = now - this.intervalMs
    // Remove old requests outside the time window
    this.requestTimes = this.requestTimes.filter((time) => time > windowStart)
    // Check if we're within rate limits
    if (this.requestTimes.length >= this.maxRequests) {
      return false
    }
    // Check burst limit (requests in last 100ms)
    const recentRequests = this.requestTimes.filter(
      (time) => now - time < 100
    ).length
    return recentRequests < this.burstLimit
  }
  recordRequest(): void {
    this.requestTimes.push(Date.now())
  }
}
