import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { Grid } from '@/shared/components/ui/grid'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'
import { Download, Filter } from 'lucide-react'

export const _RepairAnalyticsPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    { month: 'January', repairs: 85, revenue: 2550 },
    { month: 'February', repairs: 95, revenue: 2850 },
    { month: 'March', repairs: 120, revenue: 3600 },
    { month: 'April', repairs: 110, revenue: 3300 },
    { month: 'May', repairs: 130, revenue: 3900 },
    { month: 'June', repairs: 150, revenue: 4500 },
  ]

  const mockTechPerformanceData = [
    { label: 'Mike Johnson', value: 245 },
    { label: 'Lisa Chen', value: 180 },
    { label: 'James Wilson', value: 210 },
    { label: 'Sarah Martinez', value: 150 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Repair Analytics</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            <Filter size={16} className="inline mr-2" />
            Filter Data
          </button>
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            <Download size={16} className="inline mr-2" />
            Export Report
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Total Revenue
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              $20.7K
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+15.3% vs Last Month</Text>
            <Text>Target: $22K</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Customer Satisfaction
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              4.8
            </Text>
            <Text className="text-neutral-400">Average Rating</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+0.2 vs Last Month</Text>
            <Text>Target: 4.9</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Repair Success Rate
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              98.5%
            </Text>
            <Text className="text-neutral-400">Successful Repairs</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+1.2% vs Last Month</Text>
            <Text>Target: 99%</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Repair & Revenue Trends
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockTimelineData}
              dataKeys={['repairs', 'revenue']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Technician Performance
          </Text>
          <div className="w-full h-64">
            <BarChart
              data={mockTechPerformanceData}
              className="w-full"
              height={256}
              yLabel="Repairs Completed"
            />
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Key Insights
          </Text>
          <div className="space-y-4">
            <div className="p-4 bg-green-400/10 rounded-lg">
              <Text variant="label" className="text-green-400">
                Positive Trends
              </Text>
              <ul className="mt-2 space-y-1 text-sm text-green-400">
                <li>• Customer satisfaction increased by 0.2 points</li>
                <li>• Revenue grew 15.3% compared to last month</li>
                <li>• Repair success rate improved to 98.5%</li>
              </ul>
            </div>
            <div className="p-4 bg-yellow-400/10 rounded-lg">
              <Text variant="label" className="text-yellow-400">
                Areas for Improvement
              </Text>
              <ul className="mt-2 space-y-1 text-sm text-yellow-400">
                <li>• Average repair time slightly increased</li>
                <li>• Repair costs up by 2.1%</li>
                <li>• Technician capacity nearing limits</li>
              </ul>
            </div>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Performance Metrics
          </Text>
          <div className="space-y-6">
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">Average Repair Time</Text>
                <Text variant="label">3.2 days</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-blue-400 h-2 rounded-full"
                  style={{ width: '80%' }}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">Technician Utilization</Text>
                <Text variant="label">85%</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-green-400 h-2 rounded-full"
                  style={{ width: '85%' }}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">First-Time Fix Rate</Text>
                <Text variant="label">92%</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-yellow-400 h-2 rounded-full"
                  style={{ width: '92%' }}
                />
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
