import { VoiceCommandEvent } from './types'
interface VoiceEventBusConfig {
  maxEvents?: number
  retentionPeriod?: number
}
class VoiceEventStore {
  private events: VoiceCommandEvent[] = []
  private maxEvents: number
  private retentionPeriod: number
  constructor(config: VoiceEventBusConfig = {}) {
    this.maxEvents = config.maxEvents || 1000
    this.retentionPeriod = config.retentionPeriod || 24 * 60 * 60 * 1000 // 24 hours
  }
  addEvent(event: VoiceCommandEvent): void {
    this.events.push(event)
    this.cleanup()
  }
  getEvents(): VoiceCommandEvent[] {
    return this.events
  }
  private cleanup(): void {
    const now = Date.now()
    this.events = this.events
      .filter((event) => {
        const timestamp =
          'timestamp' in event.payload
            ? (event.payload.timestamp as number)
            : now
        return now - timestamp < this.retentionPeriod
      })
      .slice(-this.maxEvents)
  }
}
export class VoiceEventBus {
  private static instance: VoiceEventBus
  private eventStore: VoiceEventStore
  private listeners: Map<string, Array<(_event: VoiceCommandEvent) => void>>
  private constructor(config: VoiceEventBusConfig = {}) {
    this.eventStore = new VoiceEventStore(config)
    this.listeners = new Map()
  }
  static getInstance(config: VoiceEventBusConfig = {}): VoiceEventBus {
    if (!VoiceEventBus.instance) {
      VoiceEventBus.instance = new VoiceEventBus(config)
    }
    return VoiceEventBus.instance
  }
  emit(
    type: VoiceCommandEvent['type'],
    payload: VoiceCommandEvent['payload']
  ): void {
    const event: VoiceCommandEvent = { type, payload }
    this.eventStore.addEvent(event)
    const handlers = this.listeners.get(type) || []
    handlers.forEach((handler) => handler(event))
  }
  on(
    type: VoiceCommandEvent['type'],
    handler: (_event: VoiceCommandEvent) => void
  ): void {
    const handlers = this.listeners.get(type) || []
    handlers.push(handler)
    this.listeners.set(type, handlers)
  }
  off(
    type: VoiceCommandEvent['type'],
    handler: (_event: VoiceCommandEvent) => void
  ): void {
    const handlers = this.listeners.get(type) || []
    const index = handlers.indexOf(handler)
    if (index !== -1) {
      handlers.splice(index, 1)
      this.listeners.set(type, handlers)
    }
  }
  getEvents(): VoiceCommandEvent[] {
    return this.eventStore.getEvents()
  }
}
