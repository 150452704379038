import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  TooltipItem,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { cn } from '@/shared/lib/utils'

ChartJS.register(ArcElement, Tooltip, Legend)

interface PieChartProps {
  data: Array<{
    name: string
    value: number
    color?: string
  }>
  _width?: number
  _height?: number
  colors?: string[]
  className?: string
}

export function PieChart({
  data,
  _width,
  _height,
  colors = [],
  className,
}: PieChartProps): JSX.Element {
  const chartData = {
    labels: data.map((d) => d.name),
    datasets: [
      {
        data: data.map((d) => d.value),
        backgroundColor: data.map(
          (d, i) => d.color || colors[i] || `hsl(${i * 45}, 70%, 50%)`
        ),
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: 'currentColor',
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'pie'>) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`
          },
        },
      },
    },
  }

  return (
    <div className={cn('relative', className)}>
      <Pie data={chartData} options={options} />
    </div>
  )
}

PieChart.displayName = 'PieChart'
