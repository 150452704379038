import React from 'react'
import { cn } from '@/shared/utils/cn'

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  columns?: number
  gap?: number
}

export function Grid({
  children,
  columns = 1,
  gap = 4,
  className,
  ...props
}: GridProps): React.ReactNode {
  return (
    <div
      className={cn('grid', `grid-cols-${columns}`, `gap-${gap}`, className)}
      {...props}
    >
      {children}
    </div>
  )
}
