import React from 'react'

export interface FileUploadProps {
  accept?: string
  multiple?: boolean
  onChange: (files: File | File[]) => void
  className?: string
}

export const FileUpload: React.FC<FileUploadProps> = ({
  accept,
  multiple = false,
  onChange,
  className = '',
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) return

    if (multiple) {
      onChange(Array.from(files))
    } else {
      onChange(files[0])
    }
  }

  return (
    <div className={`relative ${className}`}>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleChange}
        className="block w-full text-sm text-slate-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-violet-50 file:text-violet-700
          hover:file:bg-violet-100"
      />
    </div>
  )
}
