import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { Grid } from '@/shared/components/ui/grid'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'
import { Download, Plus } from 'lucide-react'

export const _RecycleOverviewPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    { month: 'January', items: 320, processed: 310 },
    { month: 'February', items: 380, processed: 365 },
    { month: 'March', items: 450, processed: 440 },
    { month: 'April', items: 420, processed: 410 },
    { month: 'May', items: 480, processed: 470 },
    { month: 'June', items: 520, processed: 505 },
  ]

  const mockCompositionData = [
    { label: 'Plastics', value: 45 },
    { label: 'Textiles', value: 30 },
    { label: 'Metals', value: 15 },
    { label: 'Other', value: 10 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Recycle Overview</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            <Download size={16} className="inline mr-2" />
            Export Data
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            <Plus size={16} className="inline mr-2" />
            New Program
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Total Items
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              2,570
            </Text>
            <Text className="text-neutral-400">Items This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+8.3% vs Last Month</Text>
            <Text>Target: 3,000</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Recovery Rate
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              97.2%
            </Text>
            <Text className="text-neutral-400">Materials Recovered</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+1.5% vs Last Month</Text>
            <Text>Target: 98%</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Processing Time
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              2.8
            </Text>
            <Text className="text-neutral-400">Days Average</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>-0.3 days vs Last Month</Text>
            <Text>Target: 2.5</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Recycling Timeline
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockTimelineData}
              dataKeys={['items', 'processed']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Material Composition
          </Text>
          <div className="w-full h-64">
            <BarChart
              data={mockCompositionData}
              className="w-full"
              height={256}
              yLabel="Percentage"
            />
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Recent Activity
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Batch #RCY00789456</Text>
                <Text variant="body" className="text-neutral-400">
                  480 items processed
                </Text>
              </div>
              <Text variant="body" className="text-neutral-400">
                2 hours ago
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Batch #RCY00789455</Text>
                <Text variant="body" className="text-neutral-400">
                  325 items received
                </Text>
              </div>
              <Text variant="body" className="text-neutral-400">
                4 hours ago
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <Text variant="label">Batch #RCY00789454</Text>
                <Text variant="body" className="text-neutral-400">
                  290 items sorted
                </Text>
              </div>
              <Text variant="body" className="text-neutral-400">
                6 hours ago
              </Text>
            </div>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Processing Status
          </Text>
          <div className="space-y-6">
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">Sorting</Text>
                <Text variant="label">85%</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-blue-400 h-2 rounded-full"
                  style={{ width: '85%' }}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">Processing</Text>
                <Text variant="label">60%</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-green-400 h-2 rounded-full"
                  style={{ width: '60%' }}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-2">
                <Text variant="label">Quality Check</Text>
                <Text variant="label">40%</Text>
              </div>
              <div className="w-full bg-neutral-700 rounded-full h-2">
                <div
                  className="bg-yellow-400 h-2 rounded-full"
                  style={{ width: '40%' }}
                />
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
