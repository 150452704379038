import React from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Card } from '@/shared/components/ui/card'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'
import { _Help as Help } from '@/assets/icons/Icons'
import { _KioskCard as KioskCard } from '@/components/Kiosk/KioskCard'

const CardData = [
  {
    title: 'Shop',
    description: 'Browse our latest collection',
    icon: 'ShoppingBag',
    to: '/shop',
  },
  {
    title: 'Return',
    description: 'Easy returns and recycling',
    icon: 'Iteration',
    to: '/return',
  },
  {
    title: 'Repair',
    description: "Extend your shoe's life",
    icon: 'FadingCircle',
    to: '/repair',
  },
]

interface ActionTileProps {
  to: string
  title: string
  description: string
  color: string
  textColor: string
  icon: React.ReactNode
}

interface RecommendedProductCardProps {
  name: string
  price: number
  image: string
}

export const _HomePage: React.FC = (): JSX.Element => {
  const _isAuthenticated = false
  const _userName = 'Guest'
  return (
    <div className="min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4">
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className="fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none"></div>
      {/* Background */}
      <img
        src={kioskBackground}
        alt="Kiosk Background"
        className="w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom"
      />

      {/* Content */}
      <div className="w-full absolute py-8 top-0 px-8 left-0 flex justify-end">
        <KioskButton>
          <img
            src={UserImage}
            alt="User Image"
            className="w-6 h-6 rounded-full -ml-1"
          />
          <div className="text-lg font-light">Hey, Phil</div>
        </KioskButton>
      </div>
      <img src={OnLogo} alt="On Logo" className="w-16 h-16 mb-4" />
      <h1 className="text-[2.5rem] font-medium font-manrope text-white">
        Welcome to On
      </h1>
      <p className="text-lg font-light text-text-secondary w-full text-center border-b border-white/50 pb-12">
        The future of sustainable footwear
      </p>
      <p className="text-2xl sm-text-3xl font-light pt-12 pb-4 text-center">
        What would you like to do today?
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-fit mx-auto">
        {CardData.map(
          (card): JSX.Element => (
            <KioskCard
              key={card.title}
              title={card.title}
              description={card.description}
              icon={card.icon}
              to={card.to}
            />
          )
        )}
      </div>
      <div className="fixed z-20 bottom-0 left-0 w-screen flex justify-end p-8">
        <KioskButton>
          <Help className="w-8 h-8" />
        </KioskButton>
      </div>
    </div>
  )
}

const _ActionTile: React.FC<ActionTileProps> = ({
  to,
  title,
  description,
  color,
  textColor,
  icon,
}): JSX.Element => (
  <NavLink to={to}>
    <motion.div
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.2 }}
      className={`rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow ${color}`}
    >
      <Card
        className={`flex flex-col items-center justify-center p-8 bg-transparent border-0 ${textColor}`}
      >
        <span className="text-4xl mb-4">{icon}</span>
        <h3 className="font-semibold text-2xl mb-2">{title}</h3>
        <p className="text-lg opacity-90 text-center">{description}</p>
      </Card>
    </motion.div>
  </NavLink>
)

const _RecommendedProductCard: React.FC<RecommendedProductCardProps> = ({
  name,
  price,
  image,
}): JSX.Element => (
  <motion.div
    initial={{ y: 20, opacity: 0 }}
    whileInView={{ y: 0, opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.3 }}
  >
    <Card className="flex items-center gap-6 mb-4 p-4 bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow">
      <img
        src={image}
        alt={name}
        className="w-24 h-24 object-cover rounded-lg"
      />
      <div className="flex-1">
        <h4 className="font-semibold text-xl text-gray-900 dark:text-gray-100">
          {name}
        </h4>
        <p className="text-gray-600 dark:text-gray-300 text-lg">
          ${price.toFixed(2)}
        </p>
      </div>
      <NavLink
        to={`/shop/products`}
        className="bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600 px-6 py-2 rounded-lg text-lg font-semibold transition-colors"
      >
        View
      </NavLink>
    </Card>
  </motion.div>
)
