import { ProcessedItemsData, MetricOverview } from './types';
// Mock data with explicit typing to match GraphQL schema
export const _mockProcessedItems: ProcessedItemsData[] = [
    { date: '2024-01-03', resale: 200, donateResale: 100, recycle: 50 },
    { date: '2024-01-06', resale: 300, donateResale: 150, recycle: 70 },
    { date: '2024-01-09', resale: 400, donateResale: 180, recycle: 100 },
    { date: '2024-01-12', resale: 450, donateResale: 200, recycle: 120 },
    { date: '2024-01-15', resale: 500, donateResale: 220, recycle: 140 },
    { date: '2024-01-18', resale: 600, donateResale: 300, recycle: 200 },
    { date: '2024-01-21', resale: 650, donateResale: 320, recycle: 210 },
    { date: '2024-01-24', resale: 700, donateResale: 350, recycle: 230 },
    { date: '2024-01-27', resale: 750, donateResale: 400, recycle: 250 },
    { date: '2024-01-30', resale: 800, donateResale: 450, recycle: 300 }
];
export const _mockMetrics: MetricOverview[] = [
    {
        label: 'Recommerce Rate',
        value: 55.0,
        change: '+2.3%',
        trend: 'up',
        unit: '%'
    },
    {
        label: 'Total Revenue',
        value: 108244.0,
        change: '+1.15%',
        trend: 'up',
        unit: '$'
    },
    {
        label: 'Avg. Processing Time',
        value: 2.5,
        change: '+6.8%',
        trend: 'up',
        unit: 'hrs'
    },
    {
        label: 'Profit Margin',
        value: 21.45,
        change: '+0.25%',
        trend: 'up',
        unit: '%'
    },
    {
        label: 'Customers',
        value: 257459.0,
        change: '-3.2%',
        trend: 'down',
        unit: null
    }
];
