import { useCallback, useEffect, useRef, useState } from 'react'
import { VoiceProcessor } from '../voice-processor'
import { generateId } from '@/shared/utils/id'
import type {
  VoiceCommand,
  VoiceFlowHandler,
  VoiceCommandEvent,
  VoiceProcessingError,
  VoiceProcessingResult,
} from '../types'
import type { ConversationState } from '../../../retail/use-cases/conversations/types'
import { VoiceEventBus } from '../events'

interface VoiceProcessorState {
  isListening: boolean
  transcript: string
  confidence: number
  lastCommand?: VoiceCommand
  error?: VoiceProcessingError
}

interface VoiceProcessorHookResult {
  isListening: boolean
  transcript: string
  confidence: number
  lastCommand?: VoiceCommand
  error?: VoiceProcessingError
  startListening: () => Promise<void>
  stopListening: () => void
  clearError: () => void
  clearTranscript: () => void
  clearLastCommand: () => void
  reset: () => void
  handleVoiceCommand: (command: VoiceCommand) => void
  handleVoiceResult: (result: VoiceProcessingResult) => void
}

interface VoiceProcessorOptions {
  onError?: (error: VoiceProcessingError) => void
  initialState: Partial<VoiceProcessorState>
}

export function useVoiceProcessor({
  onError,
  initialState,
}: VoiceProcessorOptions): VoiceProcessorHookResult {
  const [state, setState] = useState<VoiceProcessorState>({
    isListening: false,
    transcript: '',
    confidence: 0,
    ...initialState,
  })

  const processorRef = useRef<VoiceProcessor | null>(null)
  const eventBusRef = useRef<VoiceEventBus | null>(null)

  useEffect(() => {
    if (!processorRef.current) {
      eventBusRef.current = VoiceEventBus.getInstance()
      const initialState: ConversationState = {
        path: '/',
        flow: {
          stage: 'welcome',
          context: {},
          history: {
            actions: [],
            commands: [],
            responses: [],
            timestamps: [],
          },
          ui: {
            activeSection: 'home',
          },
        },
        customerInfo: {
          name: '',
          membershipStatus: 'guest',
          visitCount: 0,
          currentTime: new Date(),
          deviceType: 'desktop',
          hasItemInHand: false,
          preferences: {
            categories: [],
            sustainabilityFocus: false,
            timeOfDay: 'day',
          },
        },
        loyaltyPoints: {
          current: 0,
        },
      }
      const handlers: VoiceFlowHandler[] = []
      processorRef.current = new VoiceProcessor(
        initialState,
        handlers,
        eventBusRef.current,
        {
          maxRequestsPerInterval: 10,
          intervalMs: 1000,
          burstLimit: 3,
          maxHistoryEntries: 100,
          historyRetentionPeriod: 24 * 60 * 60 * 1000, // 24 hours
        }
      )
    }

    return () => {
      if (processorRef.current) {
        processorRef.current.cleanup()
      }
    }
  }, [])

  const startListening = useCallback(async () => {
    if (!processorRef.current || state.isListening) {
      return
    }
    try {
      setState((prev) => ({ ...prev, isListening: true }))
      await processorRef.current.start()
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isListening: false,
        error: error as VoiceProcessingError,
      }))
      if (onError) {
        onError(error as VoiceProcessingError)
      }
    }
  }, [state.isListening, onError])

  const stopListening = useCallback(() => {
    if (!processorRef.current || !state.isListening) {
      return
    }
    processorRef.current.stop()
    setState((prev) => ({ ...prev, isListening: false }))
  }, [state.isListening])

  const clearError = useCallback(() => {
    setState((prev) => ({ ...prev, error: undefined }))
  }, [])

  const clearTranscript = useCallback(() => {
    setState((prev) => ({ ...prev, transcript: '' }))
  }, [])

  const clearLastCommand = useCallback(() => {
    setState((prev) => ({ ...prev, lastCommand: undefined }))
  }, [])

  const reset = useCallback(() => {
    setState({
      isListening: false,
      transcript: '',
      confidence: 0,
      lastCommand: undefined,
      error: undefined,
    })
    if (processorRef.current) {
      processorRef.current.reset()
    }
  }, [])

  useEffect(() => {
    const eventBus = eventBusRef.current
    if (!eventBus) return

    const handleCommand = (event: VoiceCommandEvent): void => {
      if (event.type === 'VOICE_PROCESSING' && 'command' in event.payload) {
        setState((prev) => ({
          ...prev,
          lastCommand: (event.payload as VoiceProcessingResult).command,
        }))
      }
    }

    const handleError = (event: VoiceCommandEvent): void => {
      if (event.type === 'VOICE_ERROR') {
        const error = event.payload as VoiceProcessingError
        setState((prev) => ({ ...prev, error }))
        if (onError) {
          onError(error)
        }
      }
    }

    eventBus.on('VOICE_PROCESSING', handleCommand)
    eventBus.on('VOICE_ERROR', handleError)

    return () => {
      eventBus.off('VOICE_PROCESSING', handleCommand)
      eventBus.off('VOICE_ERROR', handleError)
    }
  }, [onError])

  const handleVoiceCommand = useCallback((command: VoiceCommand): void => {
    if (!processorRef.current) return
    processorRef.current.processCommand(command)
  }, [])

  const handleVoiceResult = useCallback(
    (result: VoiceProcessingResult): void => {
      if (!processorRef.current || !result.response) return
      processorRef.current.processCommand({
        id: generateId(),
        text: result.response,
        type: 'NAVIGATION',
        confidence: result.confidence,
        timestamp: Date.now(),
      })
    },
    []
  )

  return {
    ...state,
    startListening,
    stopListening,
    clearError,
    clearTranscript,
    clearLastCommand,
    reset,
    handleVoiceCommand,
    handleVoiceResult,
  }
}
