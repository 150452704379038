import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { Card } from '@/shared/components/ui/card';
// Mock data
const mockDetail = {
    id: 'LRPLY00645987',
    status: 'In Transit',
    facility: 'USCA005 • California, United States',
    dateReceived: '10 Oct 2023',
    weight: '0.80 kgs',
    fills: 26,
    carbonSavings: '0.08 mt',
    progressSteps: [
        { label: 'Received', completed: true },
        { label: 'Processing', completed: true },
        { label: 'In Transit', completed: true },
        { label: 'Delivered', completed: false },
    ],
    trackingHistory: [
        { title: 'In Transit', location: '', date: '12 Oct at 21:45' },
        {
            title: 'Outbound Scan',
            location: 'USCA005 • California, United States',
            date: '12 Oct at 21:45',
        },
        { title: 'Processing', location: '', date: '11 Oct at 14:08' },
        {
            title: 'Processing Started',
            location: 'USCA005 • California, United States',
            date: '11 Oct at 14:08',
        },
        { title: 'Received', location: '', date: '11 Oct at 08:28' },
        {
            title: 'Inbound Scan',
            location: 'USCA005 • California, United States',
            date: '11 Oct at 08:28',
        },
        {
            title: 'Collection Scan',
            location: '200 West Arbor Drive, San Diego, CA 92103',
            date: '6 Oct at 16:54',
        },
    ],
};
export const _RefillBoxDetailPage: React.FC = (): void => {
    const { id } = useParams<{
        id: string;
    }>();
    const data = id ? mockDetail : null;
    if (!data) {
        return <div>Box not found</div>;
    }
    return (<div>
      <nav className='flex items-center text-sm text-text-secondary mb-4'>
        <NavLink to='/admin/refills' className='hover:text-text-primary'>
          Refills
        </NavLink>
        <ChevronRight size={16} className='mx-2'/>
        <NavLink to='/admin/refills/return-information' className='hover:text-text-primary'>
          Return Information
        </NavLink>
        <ChevronRight size={16} className='mx-2'/>
        <span className='text-text-primary font-medium'>{data.id}</span>
      </nav>

      <header className='flex items-center justify-between mb-6'>
        <h1 className='text-2xl font-semibold text-text-primary'>
          {data.id}{' '}
          <span className='text-sm bg-bg-panel text-text-primary px-2 py-1 rounded ml-2'>
            {data.status}
          </span>
        </h1>
      </header>

      {/* Progress */}
      <section>
        <Card>
          <div className='flex items-center'>
            {data.progressSteps.map((step, i): void => (<React.Fragment key={i}>
                <div className='flex flex-col items-center'>
                  <div className={`w-6 h-6 rounded-full ${step.completed ? 'bg-accent-green' : 'bg-border-base'}`}></div>
                  <span className='text-xs text-text-secondary mt-2'>{step.label}</span>
                </div>
                {i < data.progressSteps.length - 1 && (<div className='flex-1 border-t border-border-base mx-2'></div>)}
              </React.Fragment>))}
          </div>
        </Card>
      </section>

      <section className='grid grid-cols-3 gap-8 mt-8'>
        {/* Overview */}
        <Card>
          <h3 className='text-lg font-semibold text-text-primary mb-4'>Overview</h3>
          <div className='space-y-4'>
            <div>
              <div className='text-text-secondary'>Current facility</div>
              <div className='text-text-primary'>{data.facility}</div>
            </div>
            <div>
              <div className='text-text-secondary'>Date Received</div>
              <div className='text-text-primary'>{data.dateReceived}</div>
            </div>
            <div>
              <div className='text-text-secondary'>Weight</div>
              <div className='text-text-primary'>{data.weight}</div>
            </div>
            <div>
              <div className='text-text-secondary'>Number of Fills</div>
              <div className='text-text-primary'>{data.fills}</div>
            </div>
            <div>
              <div className='text-text-secondary'>Carbon Savings</div>
              <div className='text-text-primary'>{data.carbonSavings}</div>
            </div>
          </div>
        </Card>

        {/* Tracking History */}
        <Card className='col-span-2'>
          <h3 className='text-lg font-semibold text-text-primary mb-4'>Tracking History</h3>
          <div className='space-y-4'>
            {data.trackingHistory.map((event, i): void => (<div key={i} className='flex items-start gap-4'>
                <div className='flex flex-col items-center'>
                  <div className={`w-4 h-4 rounded-full ${i === 0 ? 'bg-accent-green' : 'bg-border-base'} border-2 border-border-base`}></div>
                  {i < data.trackingHistory.length - 1 && (<div className='w-0.5 h-full bg-border-base mt-1'></div>)}
                </div>
                <div className='text-sm flex-1'>
                  <div className='font-medium text-text-primary'>{event.title}</div>
                  {event.location && <div className='text-text-secondary'>{event.location}</div>}
                  <div className='text-text-secondary'>{event.date}</div>
                </div>
              </div>))}
          </div>
        </Card>
      </section>
    </div>);
};
