import React from 'react'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'

export const _ResaleOverviewPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    { month: 'Jan', sales: 4200, revenue: 12600 },
    { month: 'Feb', sales: 3800, revenue: 11400 },
    { month: 'Mar', sales: 5100, revenue: 15300 },
    { month: 'Apr', sales: 4800, revenue: 14400 },
    { month: 'May', sales: 5600, revenue: 16800 },
    { month: 'Jun', sales: 5900, revenue: 17700 },
  ]

  const mockCategoryData = [
    { label: 'Shoes', value: 450 },
    { label: 'Apparel', value: 320 },
    { label: 'Accessories', value: 180 },
    { label: 'Equipment', value: 50 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Resale Overview</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            Export Data
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            New Listing
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Total Items Listed
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              1,234
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+8.5% vs Last Month</Text>
            <Text>Target: 1,500</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Average Sale Price
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              $45.67
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+3.2% vs Last Month</Text>
            <Text>Target: $50.00</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Active Listings
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              342
            </Text>
            <Text className="text-neutral-400">Current</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>28 new this month</Text>
            <Text>Target: 400</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Sales & Revenue Trend
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockTimelineData}
              dataKeys={['sales', 'revenue']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Sales by Category
          </Text>
          <div className="w-full h-64">
            <BarChart
              data={mockCategoryData}
              className="w-full"
              height={256}
              yLabel="Count"
            />
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Top Selling Items
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Text variant="body">Cloudflow</Text>
              <Text variant="body">$89.99 (42 sold)</Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Cloudmonster</Text>
              <Text variant="body">$79.99 (38 sold)</Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Performance T</Text>
              <Text variant="body">$34.99 (31 sold)</Text>
            </div>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Best Performing Locations
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Text variant="body">California, US</Text>
              <Text variant="body">$45,678 (32%)</Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">New York, US</Text>
              <Text variant="body">$35,890 (25%)</Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Ontario, CA</Text>
              <Text variant="body">$28,456 (20%)</Text>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
