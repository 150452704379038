import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'
import { Text } from '@/shared/components/ui/text'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'

export const _RecycleFinancialsPage: React.FC = (): void => {
  const revenueData = React.useMemo((): void => {
    return [
      {
        timestamp: Date.now() - 6 * 24 * 60 * 60 * 1000,
        value: 12500,
        label: 'Revenue',
      },
      {
        timestamp: Date.now() - 5 * 24 * 60 * 60 * 1000,
        value: 13200,
        label: 'Revenue',
      },
      {
        timestamp: Date.now() - 4 * 24 * 60 * 60 * 1000,
        value: 12800,
        label: 'Revenue',
      },
      {
        timestamp: Date.now() - 3 * 24 * 60 * 60 * 1000,
        value: 14500,
        label: 'Revenue',
      },
      {
        timestamp: Date.now() - 2 * 24 * 60 * 60 * 1000,
        value: 13800,
        label: 'Revenue',
      },
      {
        timestamp: Date.now() - 1 * 24 * 60 * 60 * 1000,
        value: 15200,
        label: 'Revenue',
      },
      { timestamp: Date.now(), value: 16500, label: 'Revenue' },
    ]
  }, [])
  return (
    <div className="space-y-8">
      <h2 className="text-xl font-semibold">Financials</h2>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Revenue
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              $156,789
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+12% vs Last Month</Text>
            <Text>Target: $160,000</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Operating Costs
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              $98,456
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>-5% vs Last Month</Text>
            <Text>Budget: $100,000</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Net Margin
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              37.2%
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+2.5% vs Last Month</Text>
            <Text>Target: 35%</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Revenue Trend
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={revenueData}
              dataKeys={['value']}
              xAxisKey="timestamp"
              className="h-64"
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Cost Breakdown
          </Text>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center mb-1">
                <Text>Labor</Text>
                <Text>$45,678 (46.4%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-blue-400 h-full rounded-full"
                  style={{ width: '46.4%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-1">
                <Text>Equipment</Text>
                <Text>$28,456 (28.9%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-green-400 h-full rounded-full"
                  style={{ width: '28.9%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-1">
                <Text>Transportation</Text>
                <Text>$15,678 (15.9%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-yellow-400 h-full rounded-full"
                  style={{ width: '15.9%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-1">
                <Text>Other</Text>
                <Text>$8,644 (8.8%)</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-red-400 h-full rounded-full"
                  style={{ width: '8.8%' }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
