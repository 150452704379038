import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { Text } from '@/shared/components/ui/text'

export const _RepairProgramPage: React.FC = (): JSX.Element => {
  const location = useLocation()
  const tabs = [
    { path: '/admin/repairs/overview', label: 'Overview' },
    { path: '/admin/repairs/list', label: 'Repairs List' },
    { path: '/admin/repairs/technicians', label: 'Technicians' },
    { path: '/admin/repairs/analytics', label: 'Analytics' },
  ]

  return (
    <div className="py-6">
      <div className="flex justify-between items-center mb-6">
        <Text variant="h2">Repair Program</Text>
        <div className="flex gap-3">
          <button className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200">
            Export Data
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
            New Repair
          </button>
        </div>
      </div>

      <nav className="tabs-list mb-6">
        {tabs.map((tab) => (
          <NavLink
            key={tab.path}
            to={tab.path}
            className={({ isActive }) =>
              `tab-trigger ${
                isActive
                  ? 'border-b-2 border-accent-purple text-text-primary'
                  : ''
              }`
            }
          >
            {tab.label}
          </NavLink>
        ))}
      </nav>

      <div className="card">
        <Outlet />
      </div>
    </div>
  )
}
