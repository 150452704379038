import React from 'react'
import { Panel } from 'reactflow'
import { ScrollArea } from '@/shared/components/ui/scroll-area'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'
import { PlusCircle } from 'lucide-react'

interface AgentComponent {
  type: string
  label: string
  description: string
  inputs: string[]
  outputs: Record<string, string>
}

const agentComponents: AgentComponent[] = [
  {
    type: 'customer-insight',
    label: 'Customer Insight Agent',
    description: 'Analyzes customer data and preferences',
    inputs: ['customer_data'],
    outputs: { insights: 'CustomerInsights' },
  },
  {
    type: 'size-fit',
    label: 'Size/Fit Agent',
    description: 'Provides size and fit recommendations',
    inputs: ['customer_measurements'],
    outputs: { recommendations: 'SizeRecommendations' },
  },
  {
    type: 'product-discovery',
    label: 'Product Discovery Agent',
    description: 'Finds relevant products based on preferences',
    inputs: ['customer_preferences'],
    outputs: { products: 'ProductList' },
  },
  {
    type: 'style-advisor',
    label: 'Style Advisor Agent',
    description: 'Provides personalized style recommendations',
    inputs: ['style_preferences'],
    outputs: { recommendations: 'StyleRecommendations' },
  },
  {
    type: 'outfit-generator',
    label: 'Outfit Generator Agent',
    description: 'Creates complete outfit recommendations',
    inputs: ['product_selection'],
    outputs: { outfits: 'OutfitList' },
  },
]

interface ComponentLibraryProps {
  onAddNode: (component: AgentComponent) => void
}

export const ComponentLibrary: React.FC<ComponentLibraryProps> = ({
  onAddNode,
}) => {
  return (
    <div className="bg-background border border-border rounded-lg w-[300px] mr-4 mt-4 shadow-lg">
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-4">Agent Components</h3>
        <ScrollArea className="h-[calc(100vh-14rem)] pr-4">
          <div className="space-y-3">
            {agentComponents.map((component) => (
              <Card
                key={component.type}
                className="p-4 hover:border-primary/50 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div className="flex-1 min-w-0">
                    <h4 className="text-sm font-medium mb-1 truncate">
                      {component.label}
                    </h4>
                    <p className="text-xs text-muted-foreground mb-2 line-clamp-2">
                      {component.description}
                    </p>
                    <div className="flex gap-2">
                      <span className="text-xs bg-blue-500/10 text-blue-500 px-2 py-1 rounded-full">
                        {component.inputs.length} Input
                        {component.inputs.length !== 1 ? 's' : ''}
                      </span>
                      <span className="text-xs bg-green-500/10 text-green-500 px-2 py-1 rounded-full">
                        {Object.keys(component.outputs).length} Output
                        {Object.keys(component.outputs).length !== 1 ? 's' : ''}
                      </span>
                    </div>
                  </div>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => onAddNode(component)}
                    className="h-8 w-8 ml-2 shrink-0"
                  >
                    <PlusCircle className="h-5 w-5" />
                  </Button>
                </div>
              </Card>
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default ComponentLibrary
