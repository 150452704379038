import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from 'react-router-dom'
import { _router as router } from './router'
import { VoiceProvider } from './domain/ai/voice/context/voice-context'
import { CustomerProvider } from './domain/customer/context/customer-context'
import { _eventEmitter as eventEmitter } from './shared/utils/event-emitter'
import { CustomerService } from './domain/customer/userService'
import { _mswReady as mswReady } from './admin/api/mocks/browser'
import './shared/styles/globals.css'
const queryClient = new QueryClient()
const customerService = new CustomerService(eventEmitter)
// Initialize app after MSW is ready
mswReady.then((): void => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <CustomerProvider customerService={customerService}>
          <VoiceProvider>
            <RouterProvider router={router} />
          </VoiceProvider>
        </CustomerProvider>
      </QueryClientProvider>
    </React.StrictMode>
  )
})
