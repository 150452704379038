import React, { useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import OnLogo from '@/assets/logos/onLogo.svg'
import PentatonicLogo from '@/assets/logos/pentatonic-logo.svg'
import PentatonicLogoSmall from '@/assets/logos/pentatonic-logo-small.svg'
import UserImage from '@/assets/user/userPicture.png'
import { motion } from 'framer-motion'
import { Squash as Hamburger } from 'hamburger-react'
import { Toaster } from '@/shared/components/ui/toaster'

export const _AdminLayout: React.FC = (): JSX.Element => {
  const location = useLocation()
  const [hamburgerOpen, setHamburgerOpen] = useState(false)
  const navItems = [
    { path: '/admin', label: 'Overview' },
    { path: '/admin/inbound', label: 'Inbound' },
    { path: '/admin/refills/overview', label: 'Refills' },
    { path: '/admin/repairs/overview', label: 'Repairs' },
    { path: '/admin/recycle/overview', label: 'Recycle' },
    { path: '/admin/resale/overview', label: 'Resale' },
    { path: '/admin/ai-learning', label: 'AI Learning' },
    { path: '/admin/agent-builder', label: 'Agent Builder' },
    { path: '/admin/agent-dashboard', label: 'Agent Dashboard' },
  ]

  return (
    <div className="min-h-screen bg-bg-main dark">
      {/* Navbar */}
      <div className="fixed z-50 top-0 left-0 right-0 bg-bg-main/95 backdrop-blur-sm">
        <div className="px-4 py-2">
          <nav className="bg-bg-nav border border-border-base rounded-xl mx-auto">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative flex items-center h-16 w-full justify-between">
                {/* Left Logos */}
                <Link
                  to="/admin"
                  className="flex-shrink-0 flex items-center space-x-2 sm:space-x-3"
                >
                  <img src={OnLogo} alt="On Logo" className="h-5" />
                  <img
                    src={PentatonicLogo}
                    alt="Pentatonic Logo"
                    className="h-3.5 hidden lg:block"
                  />
                  <img
                    src={PentatonicLogoSmall}
                    alt="Pentatonic Logo"
                    className="h-8 block lg:hidden"
                  />
                </Link>

                {/* Center Nav Items */}
                <div className="hidden md:flex md:space-x-7 sm:space-x-6">
                  {navItems.map((item) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`inline-flex items-center px-1 pt-1 text-sm font-medium h-[64px] relative ${
                        location.pathname === item.path
                          ? 'text-text-primary'
                          : 'text-text-secondary hover:text-text-primary'
                      }`}
                    >
                      {item.label}
                      {location.pathname === item.path && (
                        <motion.div
                          className="absolute bottom-0 left-0 right-0 h-0.5 bg-accent-purple"
                          layoutId="underline"
                          initial={false}
                          style={{ originY: 'top' }}
                        />
                      )}
                    </Link>
                  ))}
                </div>

                {/* Right User Menu */}
                <div className="flex-shrink-0 flex items-center space-x-2 cursor-pointer">
                  <img
                    src={UserImage}
                    alt="User"
                    className="h-7 w-7 rounded-full border text-text-secondary group-hover:text-text-primary"
                  />
                  <div className="block md:hidden">
                    <Hamburger
                      toggled={hamburgerOpen}
                      toggle={setHamburgerOpen}
                      size={24}
                      color="currentColor"
                    />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* Mobile menu */}
      {hamburgerOpen && (
        <div className="fixed inset-0 z-50 bg-bg-main/95 backdrop-blur-sm">
          <div className="pt-20 pb-6 px-4 sm:px-6 overflow-y-auto">
            <div className="flex flex-col space-y-1">
              {navItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  onClick={(): void => setHamburgerOpen(false)}
                  className={`p-3 rounded-lg text-lg font-medium transition-colors ${
                    location.pathname === item.path
                      ? 'bg-bg-hover text-text-primary'
                      : 'text-text-secondary hover:bg-bg-hover hover:text-text-primary'
                  }`}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <main className="pt-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <Outlet />
        </div>
      </main>

      {/* Toast notifications */}
      <Toaster />
    </div>
  )
}
