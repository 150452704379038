import React from 'react'

interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const Icon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return <svg ref={ref} {...props} />
})
Icon.displayName = 'Icon'

export function Microphone({ size = 24, ...props }: IconProps): JSX.Element {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z" />
      <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
      <line x1="12" y1="19" x2="12" y2="23" />
      <line x1="8" y1="23" x2="16" y2="23" />
    </svg>
  )
}

export function MicOff({ size = 24, ...props }: IconProps): JSX.Element {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <line x1="1" y1="1" x2="23" y2="23" />
      <path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6" />
      <path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23" />
      <line x1="12" y1="19" x2="12" y2="23" />
      <line x1="8" y1="23" x2="16" y2="23" />
    </svg>
  )
}

export const _ShoppingBag = ({
  className,
}: {
  className?: string
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    fill="none"
    viewBox="0 0 48 49"
    className={className}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m6 12.698l6-8h24l6 8m-36 0v28a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-28m-36 0h36m-10 8a8 8 0 0 1-16 0"
    ></path>
  </svg>
)
_ShoppingBag.displayName = '_ShoppingBag'

export const _Iteration = ({
  className,
}: {
  className?: string
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    fill="none"
    viewBox="0 0 48 49"
    className={className}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8 20.698c0-8.8 7.2-16 16-16s16 7.2 16 16-7.2 16-16 16H8m0 0l8 8m-8-8l8-8"
    ></path>
  </svg>
)
_Iteration.displayName = '_Iteration'

export const _FadingCircle = ({
  className,
}: {
  className?: string
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    fill="none"
    viewBox="0 0 48 49"
    className={className}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M24 4.698a20 20 0 0 1 14.76 33.5M24 16.698v16m8-8H16m-11-6.25a20 20 0 0 0-1 6m1.66 8.25a20 20 0 0 0 4.86 6.8m-1.248-28.33a20 20 0 0 1 1.782-1.714m6.234 34.084a20 20 0 0 0 15.262-.76"
    ></path>
  </svg>
)
_FadingCircle.displayName = '_FadingCircle'

export const _Help = ({ className }: { className?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="41"
    fill="none"
    viewBox="0 0 40 41"
    className={className}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m8.217 8.915l7.067 7.066m9.433 0l7.067-7.066m-7.067 16.5l7.067 7.066m-16.5-7.066L8.217 32.48m28.45-11.783c0 9.205-7.462 16.667-16.666 16.667S3.334 29.903 3.334 20.698 10.796 4.03 20.001 4.03c9.204 0 16.666 7.462 16.666 16.667m-10 0a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0"
    ></path>
  </svg>
)
_Help.displayName = '_Help'

export const _Close = React.forwardRef<
  SVGSVGElement,
  {
    className?: string
  }
>(
  ({ className }, ref): JSX.Element => (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      className={className}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M30 10 10 30m0-20 20 20"
      ></path>
    </svg>
  )
)
_Close.displayName = '_Close'

export const _Return = ({ className }: { className?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="25"
    fill="none"
    viewBox="0 0 26 25"
    className={className}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.28 1.896A.75.75 0 0 0 8.219.835l-7.07 7.07a.75.75 0 0 0-.013 1.074l7.083 7.083a.75.75 0 0 0 1.06-1.06L3.477 9.198h13.065a7.041 7.041 0 1 1 0 14.084h-4.958a.75.75 0 0 0 0 1.5h4.958a8.543 8.543 0 0 0 7.892-11.81 8.54 8.54 0 0 0-7.892-5.274H3.477z"
      clipRule="evenodd"
    ></path>
  </svg>
)
_Return.displayName = '_Return'

export const _CreditCard = ({
  className,
}: {
  className?: string
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    fill="none"
    viewBox="0 0 48 49"
    className={className}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4 20.85h40m-36-10h32a4 4 0 0 1 4 4v20a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4v-20a4 4 0 0 1 4-4"
    ></path>
  </svg>
)
_CreditCard.displayName = '_CreditCard'

export const _Diamond = ({
  className,
}: {
  className?: string
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    fill="none"
    viewBox="0 0 48 49"
    className={className}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m44 18.85-8-12H12l-8 12m40 0-20 26m20-26H4m20 26-20-26m20 26-8-26l6-12m2 38l8-26-6-12"
    ></path>
  </svg>
)
_Diamond.displayName = '_Diamond'

export const _InlineCheck = ({
  className,
}: {
  className?: string
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    fill="none"
    viewBox="0 0 18 19"
    className={className}
  >
    <g clipPath="url(#clip0_112_385662)">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m6.75 9.85l1.5 1.5l3-3m5.25 1.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_112_385662">
        <path fill="#fff" d="M0 .85h18v18H0z"></path>
      </clipPath>
    </defs>
  </svg>
)
_InlineCheck.displayName = '_InlineCheck'

export const _Check = ({ className }: { className?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 24"
    className={className}
  >
    <circle cx="12" cy="12" r="10"></circle>
    <path d="m9 12l2 2l4-4"></path>
  </svg>
)
_Check.displayName = '_Check'
