import type { VoiceCommand, VoiceProcessingResult } from './types'

interface CommandHistoryEntry {
  command: VoiceCommand
  result: VoiceProcessingResult
  processedAt: number
}

export class CommandHistory {
  private entries: CommandHistoryEntry[] = []
  private maxEntries: number
  private retentionPeriod: number

  constructor(
    maxEntries: number = 100,
    retentionPeriod: number = 24 * 60 * 60 * 1000
  ) {
    this.maxEntries = maxEntries
    this.retentionPeriod = retentionPeriod
  }

  isDuplicate(command: VoiceCommand): boolean {
    return this.entries.some(
      (entry) =>
        entry.command.text === command.text &&
        Date.now() - entry.processedAt < this.retentionPeriod
    )
  }

  addEntry(command: VoiceCommand, result: VoiceProcessingResult): void {
    this.entries.push({ command, result, processedAt: Date.now() })
    this.cleanup()
  }

  getEntries(): CommandHistoryEntry[] {
    return [...this.entries].sort((a, b) => b.processedAt - a.processedAt)
  }

  getEntriesInTimeRange(
    startTime: number,
    endTime: number
  ): CommandHistoryEntry[] {
    return this.entries.filter(
      (entry) => entry.processedAt >= startTime && entry.processedAt <= endTime
    )
  }

  getAverageConfidence(): number {
    if (this.entries.length === 0) return 0

    const successfulCommands = this.entries.filter(
      (entry) => entry.result.success
    )
    if (successfulCommands.length === 0) return 0

    const totalConfidence = this.entries.reduce(
      (sum, entry) => sum + (entry.command.confidence || 0),
      0
    )
    return totalConfidence / this.entries.length
  }

  private cleanup(): void {
    const now = Date.now()
    this.entries = this.entries
      .filter((entry) => now - entry.processedAt < this.retentionPeriod)
      .slice(-this.maxEntries)
  }
}
