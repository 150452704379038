import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { motion } from 'framer-motion';
import { ChevronRight, FileText } from 'lucide-react';
import { Card } from '@/shared/components/ui/card';
// Mock data
const mockDetail = {
    id: 'LRPLY00645987',
    status: 'Sent to Recycler',
    productName: 'Cloudmonster 2',
    productSKU: '#2M3ME1012',
    size: 'UK11',
    outcome: 'Recycle',
    originChannel: 'Online - Direct from Consumer',
    facility: 'USCA005 • California, United States',
    weight: '0.80 kgs',
    carbonSavings: '0.08 mt',
    materialValue: 4.54,
    materialValueTrend: 0.1,
    timeToRecycle: 2.57,
    timeToRecycleTrend: 1.25,
    recycleCost: 0.34,
    recycleCostTrend: 1.82,
    profitMargin: 7,
    profitMarginTrend: 1.37,
    progressSteps: [
        { label: 'Received', completed: true },
        { label: 'Assess', completed: true },
        { label: 'In Storage', completed: true },
        { label: 'Sent to Recycler', completed: true },
    ],
    trackingHistory: [
        { title: 'Sent to Recycler', location: '', date: '12 Oct at 21:45' },
        {
            title: 'Outbound Scan',
            location: 'USCA005 • California, United States',
            date: '12 Oct at 21:45',
        },
        { title: 'In Storage', location: '', date: '11 Oct at 14:08' },
        {
            title: 'Place into storage',
            location: 'USCA005 • California, United States',
            date: '11 Oct at 14:08',
        },
        { title: 'Assessment', location: '', date: '11 Oct at 08:28' },
        {
            title: 'Outcome Logged',
            location: 'USCA005 • California, United States',
            date: '11 Oct at 08:28',
        },
        {
            title: 'Inspection',
            location: 'USCA005 • California, United States',
            date: '11 Oct at 08:12',
        },
        { title: 'Received', location: 'California, United States', date: '10 Oct at 16:15' },
        {
            title: 'Collection Scan',
            location: '200 West Arbor Drive, San Diego, CA 92103',
            date: '6 Oct at 16:54',
        },
    ],
};
const DetailRow: React.FC<{
    label: string;
    value: string;
}> = ({ label, value }): void => (<div>
    <span className='text-sm text-neutral-400'>{label}</span>
    <br />
    <span className='text-neutral-100'>{value}</span>
  </div>);
const MetricCard: React.FC<{
    title: string;
    value: string;
    trend: number;
}> = ({ title, value, trend, }): void => {
    const trendColor = trend > 0 ? 'text-green-400' : 'text-red-400';
    return (<div className='bg-neutral-800 p-4 rounded-lg'>
      <span className='text-sm text-neutral-400'>{title}</span>
      <div className='text-2xl font-bold flex items-baseline gap-2 mt-1'>
        <span>{value}</span>
        <span className={`text-sm ${trendColor}`}>{trend > 0 ? `+${trend}%` : `${trend}%`}</span>
      </div>
    </div>);
};
export const _RecycleDetailPage: React.FC = (): void => {
    const { id } = useParams<{
        id: string;
    }>();
    const data = id ? mockDetail : null; // Using id to conditionally load data
    if (!data) {
        return <div>Item not found</div>;
    }
    return (<motion.div className='text-neutral-100' initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
      <nav className='flex items-center text-sm text-neutral-300 mb-4'>
        <NavLink to='/admin/recycle' className='hover:text-neutral-100'>
          Recycle
        </NavLink>
        <ChevronRight size={16} className='mx-2'/>
        <span className='text-neutral-300'>Recycle Information</span>
        <ChevronRight size={16} className='mx-2'/>
        <span className='text-neutral-100 font-medium'>{data.id}</span>
      </nav>

      <header className='flex items-center justify-between mb-6'>
        <h1 className='text-2xl font-semibold'>
          {data.id}{' '}
          <span className='text-sm bg-neutral-700 text-neutral-200 px-2 py-1 rounded ml-2'>
            {data.status}
          </span>
        </h1>
      </header>

      <Tabs.Root defaultValue='overview'>
        <Tabs.List className='flex space-x-4 border-b border-neutral-700 mb-6'>
          <Tabs.Trigger value='overview' className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-400 data-[state=active]:text-neutral-100'>
            Overview
          </Tabs.Trigger>
          <Tabs.Trigger value='repair' className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-400 data-[state=active]:text-neutral-100'>
            Repair
          </Tabs.Trigger>
          <Tabs.Trigger value='resale' className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-400 data-[state=active]:text-neutral-100'>
            Resale
          </Tabs.Trigger>
          <Tabs.Trigger value='recycle' className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-400 data-[state=active]:text-neutral-100'>
            Recycle
          </Tabs.Trigger>
          <Tabs.Trigger value='documents' className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-400 data-[state=active]:text-neutral-100 flex items-center gap-1'>
            <FileText size={14}/>
            Documents
          </Tabs.Trigger>
          <Tabs.Trigger value='history' className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-400 data-[state=active]:text-neutral-100'>
            History
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value='overview' className='space-y-8'>
          {/* Progress */}
          <section>
            <Card className='bg-neutral-800 p-4 rounded-lg'>
              <div className='flex items-center'>
                {data.progressSteps.map((step, i): void => (<React.Fragment key={i}>
                    <div className='flex flex-col items-center'>
                      <div className={`w-6 h-6 rounded-full ${step.completed ? 'bg-green-400' : 'bg-neutral-600'}`}></div>
                      <span className='text-xs text-neutral-400 mt-2'>{step.label}</span>
                    </div>
                    {i < data.progressSteps.length - 1 && (<div className='flex-1 border-t border-neutral-600 mx-2'></div>)}
                  </React.Fragment>))}
              </div>
            </Card>
          </section>

          <section className='grid grid-cols-3 gap-8'>
            {/* Overview */}
            <Card className='bg-neutral-800 p-4 rounded-lg space-y-4'>
              <h3 className='text-lg font-semibold'>Overview</h3>
              <DetailRow label='Product' value={`${data.productName} • Size 11 • ${data.productSKU}`}/>
              <DetailRow label='SKU' value={data.productSKU}/>
              <DetailRow label='Size' value={data.size}/>
              <DetailRow label='Outcome on Assessment' value={data.outcome}/>
              <DetailRow label='Origin Channel' value={data.originChannel}/>
              <DetailRow label='Current facility' value={data.facility}/>
              <DetailRow label='Weight' value={data.weight}/>
              <DetailRow label='Carbon Savings' value={data.carbonSavings}/>
            </Card>

            {/* Key metrics */}
            <div className='grid grid-cols-2 gap-4 col-span-2'>
              <MetricCard title='Material Value' value={`$ ${data.materialValue.toFixed(2)}`} trend={data.materialValueTrend}/>
              <MetricCard title='Time to Recycle' value={`${data.timeToRecycle} days`} trend={data.timeToRecycleTrend}/>
              <MetricCard title='Recycle Cost' value={`$ ${data.recycleCost.toFixed(2)}`} trend={data.recycleCostTrend}/>
              <MetricCard title='Profit Margin' value={`${data.profitMargin}%`} trend={data.profitMarginTrend}/>

              {/* Tracking History */}
              <Card className='bg-neutral-800 p-4 rounded-lg col-span-2'>
                <h4 className='text-md font-semibold mb-4'>Tracking History</h4>
                <div className='flex flex-col space-y-4'>
                  {data.trackingHistory.map((event, i): void => (<div key={i} className='flex items-start gap-4'>
                      <div className='flex flex-col items-center'>
                        <div className={`w-4 h-4 rounded-full ${i === 0 ? 'bg-green-400' : 'bg-neutral-600'} border-2 border-neutral-700`}></div>
                      </div>
                      <div className='text-sm'>
                        <div className='font-medium text-neutral-100'>{event.title}</div>
                        {event.location && <div className='text-neutral-400'>{event.location}</div>}
                        <div className='text-neutral-400'>{event.date}</div>
                      </div>
                    </div>))}
                </div>
              </Card>
            </div>
          </section>
        </Tabs.Content>

        {/* Other tabs can be implemented similarly */}
        <Tabs.Content value='repair'>
          <div className='text-neutral-400'>Repair information will be displayed here.</div>
        </Tabs.Content>
        <Tabs.Content value='resale'>
          <div className='text-neutral-400'>Resale information will be displayed here.</div>
        </Tabs.Content>
        <Tabs.Content value='recycle'>
          <div className='text-neutral-400'>Recycle details will be displayed here.</div>
        </Tabs.Content>
        <Tabs.Content value='documents'>
          <div className='text-neutral-400'>Related documents will be displayed here.</div>
        </Tabs.Content>
        <Tabs.Content value='history'>
          <div className='text-neutral-400'>Item history will be displayed here.</div>
        </Tabs.Content>
      </Tabs.Root>
    </motion.div>);
};
