import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { LineChart } from '@/shared/components/charts'
import { Share2, Download, Filter, ArrowRight } from 'lucide-react'
import { NavLink } from 'react-router-dom'

export const _DashboardPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    {
      name: 'Jan 2024',
      value: 120,
      category: 'refills',
    },
    {
      name: 'Feb 2024',
      value: 145,
      category: 'refills',
    },
    {
      name: 'Mar 2024',
      value: 165,
      category: 'refills',
    },
    {
      name: 'Apr 2024',
      value: 185,
      category: 'refills',
    },
    {
      name: 'May 2024',
      value: 205,
      category: 'refills',
    },
    {
      name: 'Jun 2024',
      value: 225,
      category: 'refills',
    },
    // Repairs data
    {
      name: 'Jan 2024',
      value: 85,
      category: 'repairs',
    },
    {
      name: 'Feb 2024',
      value: 95,
      category: 'repairs',
    },
    {
      name: 'Mar 2024',
      value: 105,
      category: 'repairs',
    },
    {
      name: 'Apr 2024',
      value: 115,
      category: 'repairs',
    },
    {
      name: 'May 2024',
      value: 125,
      category: 'repairs',
    },
    {
      name: 'Jun 2024',
      value: 135,
      category: 'repairs',
    },
    // Recycle data
    {
      name: 'Jan 2024',
      value: 45,
      category: 'recycle',
    },
    {
      name: 'Feb 2024',
      value: 55,
      category: 'recycle',
    },
    {
      name: 'Mar 2024',
      value: 65,
      category: 'recycle',
    },
    {
      name: 'Apr 2024',
      value: 75,
      category: 'recycle',
    },
    {
      name: 'May 2024',
      value: 85,
      category: 'recycle',
    },
    {
      name: 'Jun 2024',
      value: 95,
      category: 'recycle',
    },
    // Resale data
    {
      name: 'Jan 2024',
      value: 65,
      category: 'resale',
    },
    {
      name: 'Feb 2024',
      value: 75,
      category: 'resale',
    },
    {
      name: 'Mar 2024',
      value: 85,
      category: 'resale',
    },
    {
      name: 'Apr 2024',
      value: 95,
      category: 'resale',
    },
    {
      name: 'May 2024',
      value: 105,
      category: 'resale',
    },
    {
      name: 'Jun 2024',
      value: 115,
      category: 'resale',
    },
  ]

  // Group data by category
  const groupedData = mockTimelineData.reduce((acc, item) => {
    const existingItem = acc.find((d) => d.name === item.name)
    if (existingItem) {
      existingItem[item.category] = item.value
    } else {
      acc.push({
        name: item.name,
        [item.category]: item.value,
      })
    }
    return acc
  }, [] as any[])

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <Text variant="h2">Admin Dashboard</Text>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-muted hover:bg-muted/80 text-foreground rounded">
            <Share2 className="w-4 h-4 inline-block mr-2" />
            Share
          </button>
          <button className="px-4 py-2 bg-muted hover:bg-muted/80 text-foreground rounded">
            <Download className="w-4 h-4 inline-block mr-2" />
            Export
          </button>
          <button className="px-4 py-2 bg-muted hover:bg-muted/80 text-foreground rounded">
            <Filter className="w-4 h-4 inline-block mr-2" />
            Filter
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <Card className="p-6">
          <Text variant="label">Total Refills</Text>
          <Text variant="h3">1,520</Text>
          <Text variant="body" className="text-emerald-500">
            +10.1% vs last month
          </Text>
        </Card>
        <Card className="p-6">
          <Text variant="label">Active Repairs</Text>
          <Text variant="h3">550</Text>
          <Text variant="body" className="text-emerald-500">
            +7.8% vs last month
          </Text>
        </Card>
        <Card className="p-6">
          <Text variant="label">Recycled Items</Text>
          <Text variant="h3">950</Text>
          <Text variant="body" className="text-emerald-500">
            +3.3% vs last month
          </Text>
        </Card>
        <Card className="p-6">
          <Text variant="label">Resale Revenue</Text>
          <Text variant="h3">$58,450</Text>
          <Text variant="body" className="text-emerald-500">
            +8.3% vs last month
          </Text>
        </Card>
      </div>

      <Card className="p-6">
        <Text variant="h3" className="mb-4">
          Program Performance
        </Text>
        <LineChart
          data={groupedData}
          dataKeys={['refills', 'repairs', 'recycle', 'resale']}
          xAxisKey="name"
          height={300}
          colors={['#10b981', '#3b82f6', '#f59e0b', '#8b5cf6']}
          className="w-full"
        />
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="p-6">
          <div className="flex justify-between items-center mb-6">
            <Text variant="h3">Program Overview</Text>
          </div>
          <div className="space-y-4">
            <NavLink
              to="/admin/refills"
              className="flex justify-between items-center p-4 bg-muted/50 rounded-lg hover:bg-muted"
            >
              <div>
                <Text variant="h3" className="text-lg">
                  Refill Program
                </Text>
                <Text variant="body" className="text-muted-foreground">
                  94.6% processing rate
                </Text>
              </div>
              <ArrowRight className="w-5 h-5 text-muted-foreground" />
            </NavLink>
            <NavLink
              to="/admin/repairs"
              className="flex justify-between items-center p-4 bg-muted/50 rounded-lg hover:bg-muted"
            >
              <div>
                <Text variant="h3" className="text-lg">
                  Repair Program
                </Text>
                <Text variant="body" className="text-muted-foreground">
                  2.3 days avg. repair time
                </Text>
              </div>
              <ArrowRight className="w-5 h-5 text-muted-foreground" />
            </NavLink>
            <NavLink
              to="/admin/recycle"
              className="flex justify-between items-center p-4 bg-muted/50 rounded-lg hover:bg-muted"
            >
              <div>
                <Text variant="h3" className="text-lg">
                  Recycle Program
                </Text>
                <Text variant="body" className="text-muted-foreground">
                  85.2% recovery rate
                </Text>
              </div>
              <ArrowRight className="w-5 h-5 text-muted-foreground" />
            </NavLink>
            <NavLink
              to="/admin/resale"
              className="flex justify-between items-center p-4 bg-muted/50 rounded-lg hover:bg-muted"
            >
              <div>
                <Text variant="h3" className="text-lg">
                  Resale Program
                </Text>
                <Text variant="body" className="text-muted-foreground">
                  $45.67 avg. sale price
                </Text>
              </div>
              <ArrowRight className="w-5 h-5 text-muted-foreground" />
            </NavLink>
          </div>
        </Card>

        <Card className="p-6">
          <Text variant="h3" className="mb-6">
            Recent Activity
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center pb-4 border-b border-border">
              <div>
                <Text variant="body" className="font-medium">
                  New Repair Request
                </Text>
                <Text variant="label" className="text-muted-foreground">
                  Cloudmonster • Size 11
                </Text>
              </div>
              <Text variant="label" className="text-muted-foreground">
                2 mins ago
              </Text>
            </div>
            <div className="flex justify-between items-center pb-4 border-b border-border">
              <div>
                <Text variant="body" className="font-medium">
                  Refill Box Processed
                </Text>
                <Text variant="label" className="text-muted-foreground">
                  Box ID: RF789012
                </Text>
              </div>
              <Text variant="label" className="text-muted-foreground">
                15 mins ago
              </Text>
            </div>
            <div className="flex justify-between items-center pb-4 border-b border-border">
              <div>
                <Text variant="body" className="font-medium">
                  Item Listed for Resale
                </Text>
                <Text variant="label" className="text-muted-foreground">
                  Performance T-Shirt
                </Text>
              </div>
              <Text variant="label" className="text-muted-foreground">
                45 mins ago
              </Text>
            </div>
            <div className="flex justify-between items-center pb-4">
              <div>
                <Text variant="body" className="font-medium">
                  Recycle Batch Completed
                </Text>
                <Text variant="label" className="text-muted-foreground">
                  Batch ID: RC456789
                </Text>
              </div>
              <Text variant="label" className="text-muted-foreground">
                1 hour ago
              </Text>
            </div>
          </div>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Card className="p-6 space-y-4">
          <Text variant="h3">System Health</Text>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text>API Response Time</Text>
                <Text>95ms</Text>
              </div>
              <div className="w-full bg-muted h-2 rounded-full">
                <div
                  className="bg-emerald-500 h-full rounded-full"
                  style={{ width: '95%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text>Server Load</Text>
                <Text>65%</Text>
              </div>
              <div className="w-full bg-muted h-2 rounded-full">
                <div
                  className="bg-blue-500 h-full rounded-full"
                  style={{ width: '65%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text>Error Rate</Text>
                <Text>0.02%</Text>
              </div>
              <div className="w-full bg-muted h-2 rounded-full">
                <div
                  className="bg-emerald-500 h-full rounded-full"
                  style={{ width: '2%' }}
                ></div>
              </div>
            </div>
          </div>
        </Card>

        <Card className="p-6 space-y-4">
          <Text variant="h3">Environmental Impact</Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Text variant="body">Carbon Footprint Saved</Text>
              <Text variant="body" className="text-emerald-500">
                15.6 tons
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Water Saved</Text>
              <Text variant="body" className="text-emerald-500">
                45,678 L
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Materials Recovered</Text>
              <Text variant="body" className="text-emerald-500">
                92.5%
              </Text>
            </div>
          </div>
        </Card>

        <Card className="p-6 space-y-4">
          <Text variant="h3">AI Learning Status</Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Text variant="body">Pattern Recognition</Text>
              <Text variant="body" className="text-emerald-500">
                95.2%
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Response Time</Text>
              <Text variant="body" className="text-emerald-500">
                95ms
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Success Rate</Text>
              <Text variant="body" className="text-emerald-500">
                98.5%
              </Text>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
