import * as React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

interface LineChartProps {
  data: Array<{ timestamp: number; value: number }>
  className?: string
  height?: number
  yLabel?: string
}

export const LineChart: React.FC<LineChartProps> = ({
  data,
  className,
  height = 300,
  yLabel = '',
}): JSX.Element => {
  const chartData: ChartData<'line'> = {
    labels: data.map((d) => new Date(d.timestamp).toLocaleDateString()),
    datasets: [
      {
        label: yLabel,
        data: data.map((d) => d.value),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }

  return (
    <div className={className}>
      <Line data={chartData} options={options} height={height} />
    </div>
  )
}

LineChart.displayName = 'LineChart'
