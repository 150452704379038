import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { ChevronRight, FileText } from 'lucide-react';
import { Text } from '@/shared/components/ui/text';
import { Card } from '@/shared/components/ui/card';
// Mock Data
const mockInboundDetail = {
    id: 'LRPLY00645987',
    status: 'Received',
    productName: (<div className="flex flex-col gap-2">
      <span>Cloudmonster 2</span>
      <img src="/images/products/on/cloudmonster.png" alt="Cloudmonster 2" className="w-24 h-24 object-contain"/>
    </div>),
    productSKU: '#2M3ME1012',
    size: 'UK11',
    outcomeOnAssessment: '-',
    originChannel: 'Online - Direct from Consumer',
    facility: 'USCA005 • California, United States',
    timeSinceReceived: 0.15,
    carbonSavings: '0.01mt',
    trackingHistory: [
        { title: 'Received', location: '', date: '10 Oct at 16:15' },
        { title: 'Received', location: 'California, United States', date: '10 Oct at 16:15' },
        {
            title: 'Collection Scan',
            location: '200 West Arbor Drive, San Diego, CA 92103',
            date: '6 Oct at 16:54',
        },
        {
            title: 'Label Generated',
            location: '200 West Arbor Drive, San Diego, CA 92103',
            date: '21 Sep at 10:14',
        },
    ],
};
interface DetailRowProps {
    label: string;
    value: string | React.ReactNode;
}
const DetailRow: React.FC<DetailRowProps> = ({ label, value }): void => (<div>
    <Text variant='label' className='text-neutral-400'>
      {label}
    </Text>
    <Text variant='body'>{value}</Text>
  </div>);
export const _InboundDetailPage: React.FC = (): void => {
    const { id } = useParams<{
        id: string;
    }>();
    const data = mockInboundDetail; // Replace with: await inboundService.getInboundDetail(id)
    // In a real app, we would use this id to fetch data
    console.log('Loading details for inbound item:', id);
    return (<div className='text-neutral-100'>
      <nav className='flex items-center text-sm text-neutral-300 mb-4'>
        <NavLink to='/admin/inbound' className='hover:text-neutral-100'>
          Inbound
        </NavLink>
        <ChevronRight size={16} className='mx-2'/>
        <span className='text-neutral-100 font-medium'>{data.id}</span>
        <span className='text-sm bg-neutral-700 text-neutral-200 px-2 py-1 rounded ml-2'>
          {data.status}
        </span>
      </nav>

      <header className='flex items-center justify-between mb-6'>
        <Text variant='h2'>{data.id}</Text>
      </header>

      <Tabs.Root defaultValue='overview' className='space-y-6'>
        <Tabs.List className='flex space-x-4 border-b border-neutral-700'>
          <Tabs.Trigger value='overview' className='px-4 py-2 text-neutral-400 hover:text-neutral-100 border-b-2 border-transparent data-[state=active]:border-neutral-100 data-[state=active]:text-neutral-100'>
            Overview
          </Tabs.Trigger>
          <Tabs.Trigger value='assess' className='px-4 py-2 text-neutral-400 hover:text-neutral-100 border-b-2 border-transparent data-[state=active]:border-neutral-100 data-[state=active]:text-neutral-100'>
            Assess
          </Tabs.Trigger>
          <Tabs.Trigger value='documents' className='px-4 py-2 text-neutral-400 hover:text-neutral-100 border-b-2 border-transparent data-[state=active]:border-neutral-100 data-[state=active]:text-neutral-100 flex items-center gap-1'>
            <FileText size={14}/>
            Documents
          </Tabs.Trigger>
          <Tabs.Trigger value='history' className='px-4 py-2 text-neutral-400 hover:text-neutral-100 border-b-2 border-transparent data-[state=active]:border-neutral-100 data-[state=active]:text-neutral-100'>
            History
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value='overview' className='space-y-8'>
          {/* Progress Section */}
          <Card className='p-4'>
            <div className='flex items-center'>
              <div className='flex flex-col items-center'>
                <div className='w-6 h-6 bg-green-400 rounded-full'></div>
                <Text variant='label' className='text-neutral-400 mt-2'>
                  Received
                </Text>
              </div>
              <div className='flex-1 border-t border-neutral-600 mx-2'></div>
              <div className='flex flex-col items-center'>
                <div className='w-6 h-6 bg-neutral-600 rounded-full'></div>
                <Text variant='label' className='text-neutral-400 mt-2'>
                  Assess
                </Text>
              </div>
              <div className='flex-1 border-t border-neutral-600 mx-2'></div>
              <div className='flex flex-col items-center'>
                <div className='w-6 h-6 bg-neutral-600 rounded-full'></div>
                <Text variant='label' className='text-neutral-400 mt-2'>
                  Graded
                </Text>
              </div>
            </div>
          </Card>

          {/* Main Content */}
          <div className='grid grid-cols-3 gap-8'>
            {/* Overview Card */}
            <Card className='p-4 space-y-4'>
              <Text variant='h3'>Overview</Text>
              <DetailRow label='Product' value={data.productName}/>
              <DetailRow label='SKU' value={data.productSKU}/>
              <DetailRow label='Size' value={data.size}/>
              <DetailRow label='Outcome on Assessment' value={data.outcomeOnAssessment}/>
              <DetailRow label='Origin Channel' value={data.originChannel}/>
              <DetailRow label='Current facility' value={data.facility}/>
              <DetailRow label='Time since Received (Days)' value={String(data.timeSinceReceived)}/>
              <DetailRow label='Carbon Savings' value={data.carbonSavings}/>
            </Card>

            {/* Tracking History */}
            <Card className='p-4 col-span-2'>
              <Text variant='h3' className='mb-4'>
                Tracking History
              </Text>
              <div className='flex flex-col space-y-4'>
                {data.trackingHistory.map((event, i): void => (<div key={i} className='flex items-start gap-4'>
                    <div className='flex flex-col items-center'>
                      <div className={`w-4 h-4 rounded-full ${i === 0 ? 'bg-green-400' : 'bg-neutral-600'} border-2 border-neutral-700`}></div>
                    </div>
                    <div className='text-sm'>
                      <Text variant='body' className='font-medium'>
                        {event.title}
                      </Text>
                      {event.location && (<Text variant='body' className='text-neutral-400'>
                          {event.location}
                        </Text>)}
                      <Text variant='body' className='text-neutral-400'>
                        {event.date}
                      </Text>
                    </div>
                  </div>))}
              </div>
            </Card>
          </div>
        </Tabs.Content>

        <Tabs.Content value='assess'>{/* Assess tab content */}</Tabs.Content>

        <Tabs.Content value='documents'>{/* Documents tab content */}</Tabs.Content>

        <Tabs.Content value='history'>{/* History tab content */}</Tabs.Content>
      </Tabs.Root>
    </div>);
};
