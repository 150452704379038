import type { EventBus } from '@/shared/utils/event-emitter'
import type { CustomerProfile } from './types/customer-profile'
import { _mockCustomerProfile as mockCustomerProfile } from './__mocks__/customer-profiles'
import type { _EventType } from '@/domain/events/types'
interface CustomerEvents {
  USER_ACTION: {
    type: 'USER_ACTION'
    action: string
    target: string
    timestamp: number
    metadata: {
      source: string
      context?: Record<string, unknown>
    }
  }
}
export class CustomerService {
  constructor(private eventBus: EventBus<CustomerEvents>) {}
  async getCustomerProfile(_userId: string): Promise<CustomerProfile> {
    // For demo purposes, always return the mock profile
    return mockCustomerProfile
  }
  async updateCustomerProfile(
    _userId: string,
    profile: Partial<CustomerProfile>
  ): Promise<CustomerProfile> {
    const updatedProfile = {
      ...mockCustomerProfile,
      ...profile,
    }
    this.eventBus.emit('USER_ACTION', {
      type: 'USER_ACTION',
      action: 'update_profile',
      target: _userId,
      timestamp: Date.now(),
      metadata: {
        source: 'customer_service',
        context: { updates: profile },
      },
    })
    return updatedProfile
  }
}
