import React from 'react'
import { useParams, NavLink } from 'react-router-dom'
import * as Tabs from '@radix-ui/react-tabs'
import { ChevronRight, FileText } from 'lucide-react'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'

interface RepairDetail {
  id: string
  status: string
  customer: string
  product: string
  dateReceived: string
  estimatedCompletion: string
  technician: string
  repairType: string
  cost: number
  timeInRepair: number
  notes: string
  progressSteps: Array<{
    label: string
    completed: boolean
  }>
  trackingHistory: Array<{
    title: string
    location: string
    date: string
  }>
}

const mockRepairDetail: RepairDetail = {
  id: 'REP00545897',
  status: 'In Progress',
  customer: 'John Smith',
  product: 'Cloudmonster • Size 11',
  dateReceived: '10 Oct 23 at 10:43',
  estimatedCompletion: '13 Oct 23',
  technician: 'Mike Johnson',
  repairType: 'Sole Replacement',
  cost: 30.0,
  timeInRepair: 2.5,
  notes:
    'Customer requested express service. Wear pattern indicates need for sole replacement.',
  progressSteps: [
    { label: 'Received', completed: true },
    { label: 'Assessment', completed: true },
    { label: 'In Repair', completed: true },
    { label: 'Quality Check', completed: false },
    { label: 'Ready', completed: false },
  ],
  trackingHistory: [
    {
      title: 'Repair Started',
      location: 'Repair Center',
      date: '11 Oct 23 at 09:15',
    },
    {
      title: 'Assessment Complete',
      location: 'Repair Center',
      date: '10 Oct 23 at 14:30',
    },
    {
      title: 'Assessment Started',
      location: 'Repair Center',
      date: '10 Oct 23 at 13:45',
    },
    {
      title: 'Item Received',
      location: 'Repair Center',
      date: '10 Oct 23 at 10:43',
    },
  ],
}

const DetailRow = ({
  label,
  value,
}: {
  label: string
  value: string | number
}): JSX.Element => (
  <div className="flex flex-col gap-1">
    <Text variant="label" className="text-gray-500">
      {label}
    </Text>
    <Text variant="body">{value}</Text>
  </div>
)

export const _RepairDetailPage: React.FC = (): JSX.Element => {
  const { id } = useParams()
  const data = mockRepairDetail // In real app, fetch based on id

  return (
    <div className="p-6">
      <nav className="flex items-center text-sm text-gray-500 mb-4">
        <NavLink to="/admin/repairs" className="hover:text-gray-700">
          Repairs
        </NavLink>
        <ChevronRight size={16} />
        <span className="text-gray-700">{data.id}</span>
      </nav>

      <header className="flex items-center justify-between mb-6">
        <div>
          <Text variant="h3">Repair Details</Text>
          <Text variant="body" className="text-gray-500">
            {data.id}
          </Text>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200">
            Download Report
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
            Update Status
          </button>
        </div>
      </header>

      {/* Progress Tracker */}
      <Card className="p-4 mb-6">
        <div className="flex items-center">
          {data.progressSteps.map((step, i) => (
            <React.Fragment key={i}>
              <div className="flex flex-col items-center">
                <div
                  className={`w-6 h-6 rounded-full ${
                    step.completed ? 'bg-green-500' : 'bg-gray-300'
                  }`}
                />
                <Text variant="body" className="text-gray-500 mt-2">
                  {step.label}
                </Text>
              </div>
              {i < data.progressSteps.length - 1 && (
                <div className="flex-1 border-t border-gray-300 mx-2" />
              )}
            </React.Fragment>
          ))}
        </div>
      </Card>

      <Tabs.Root defaultValue="overview">
        <Tabs.List className="flex space-x-4 border-b border-gray-200 mb-6">
          <Tabs.Trigger
            value="overview"
            className="px-3 py-2 text-sm text-gray-500 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-gray-700"
          >
            Overview
          </Tabs.Trigger>
          <Tabs.Trigger
            value="history"
            className="px-3 py-2 text-sm text-gray-500 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-gray-700"
          >
            History
          </Tabs.Trigger>
          <Tabs.Trigger
            value="documents"
            className="px-3 py-2 text-sm text-gray-500 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-gray-700 flex items-center gap-1"
          >
            <FileText size={14} />
            Documents
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="overview" className="space-y-6">
          <Grid cols={2} gap={6}>
            {/* Customer & Product Info */}
            <Card className="p-6 space-y-4">
              <Text variant="h3">Customer & Product</Text>
              <DetailRow label="Customer" value={data.customer} />
              <DetailRow label="Product" value={data.product} />
              <DetailRow label="Date Received" value={data.dateReceived} />
              <DetailRow
                label="Est. Completion"
                value={data.estimatedCompletion}
              />
            </Card>

            {/* Repair Info */}
            <Card className="p-6 space-y-4">
              <Text variant="h3">Repair Details</Text>
              <DetailRow label="Type" value={data.repairType} />
              <DetailRow label="Technician" value={data.technician} />
              <DetailRow label="Cost" value={`$${data.cost.toFixed(2)}`} />
              <DetailRow
                label="Time in Repair"
                value={`${data.timeInRepair} days`}
              />
            </Card>
          </Grid>

          {/* Notes */}
          <Card className="p-6">
            <Text variant="h3" className="mb-4">
              Notes
            </Text>
            <Text variant="body">{data.notes}</Text>
          </Card>
        </Tabs.Content>

        <Tabs.Content value="history" className="space-y-6">
          <Card className="p-6">
            <Text variant="h3" className="mb-4">
              Tracking History
            </Text>
            <div className="space-y-4">
              {data.trackingHistory.map((event, index) => (
                <div
                  key={index}
                  className={`flex items-start gap-4 ${
                    index === 0 ? 'text-blue-500' : 'text-gray-500'
                  }`}
                >
                  <div
                    className={`w-3 h-3 rounded-full mt-1.5 ${
                      index === 0 ? 'bg-blue-500' : 'bg-gray-300'
                    }`}
                  />
                  <div className="flex-1">
                    <Text variant="body" className="font-medium">
                      {event.title}
                    </Text>
                    {event.location && (
                      <Text variant="body" className="text-gray-500">
                        {event.location}
                      </Text>
                    )}
                    <Text variant="body" className="text-gray-500">
                      {event.date}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Tabs.Content>

        <Tabs.Content value="documents">
          <Card className="p-6">
            <Text variant="h3" className="mb-4">
              Documents
            </Text>
            <div className="text-gray-500">No documents available</div>
          </Card>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  )
}
