import React from 'react'
import { _LineChart as LineChart } from '@/shared/components/ui/charts/line-chart'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'

export const _ResaleEnvironmentPage: React.FC = (): JSX.Element => {
  const mockTimelineData = [
    { month: 'Jan', carbon: 1200, water: 4500 },
    { month: 'Feb', carbon: 1350, water: 4800 },
    { month: 'Mar', carbon: 1450, water: 5200 },
    { month: 'Apr', carbon: 1380, water: 4900 },
    { month: 'May', carbon: 1580, water: 5500 },
    { month: 'Jun', carbon: 1650, water: 5800 },
  ]

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Environmental Impact</h2>
        <div className="space-x-4">
          <button className="px-4 py-2 bg-neutral-800 text-white rounded hover:bg-neutral-700">
            Export Report
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            View Details
          </button>
        </div>
      </div>

      <Grid columns={3} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Carbon Footprint Saved
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-green-400">
              8.61mt
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+4.5% vs Last Month</Text>
            <Text>Target: 10mt</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Water Saved
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-blue-400">
              30.7kL
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>+5.5% vs Last Month</Text>
            <Text>Target: 35kL</Text>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-2">
            Material Recovery Rate
          </Text>
          <div className="text-center my-4">
            <Text variant="h2" className="text-3xl font-bold text-yellow-400">
              92.4%
            </Text>
            <Text className="text-neutral-400">This Month</Text>
          </div>
          <div className="text-sm text-neutral-400 flex justify-between">
            <Text>-0.8% vs Last Month</Text>
            <Text>Target: 95%</Text>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Environmental Savings Trend
          </Text>
          <div className="w-full h-64">
            <LineChart
              data={mockTimelineData}
              dataKeys={['carbon', 'water']}
              xAxisKey="month"
              height={256}
            />
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Impact Breakdown
          </Text>
          <div className="space-y-6">
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Carbon Reduction Target</Text>
                <Text variant="body">75% Complete</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-green-400 h-full rounded-full"
                  style={{ width: '75%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Water Conservation Target</Text>
                <Text variant="body">82% Complete</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-blue-400 h-full rounded-full"
                  style={{ width: '82%' }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <Text variant="body">Material Recovery Target</Text>
                <Text variant="body">92% Complete</Text>
              </div>
              <div className="w-full bg-neutral-700 h-2 rounded-full">
                <div
                  className="bg-yellow-400 h-full rounded-full"
                  style={{ width: '92%' }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
      </Grid>

      <Grid columns={2} gap={6}>
        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Material Recovery Details
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Text variant="body">Textiles</Text>
              <Text variant="body">450kg (45%)</Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Rubber</Text>
              <Text variant="body">320kg (32%)</Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Plastics</Text>
              <Text variant="body">180kg (18%)</Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Other Materials</Text>
              <Text variant="body">50kg (5%)</Text>
            </div>
          </div>
        </Card>

        <Card className="bg-neutral-800 p-4 rounded-lg">
          <Text variant="h3" className="mb-4">
            Environmental Certifications
          </Text>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <Text variant="body">Carbon Neutral Certified</Text>
              <Text variant="body" className="text-green-400">
                Active
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Water Stewardship</Text>
              <Text variant="body" className="text-green-400">
                Active
              </Text>
            </div>
            <div className="flex justify-between items-center">
              <Text variant="body">Zero Waste to Landfill</Text>
              <Text variant="body" className="text-yellow-400">
                In Progress
              </Text>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
