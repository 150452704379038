import { CustomerProfile } from '../users/types/customer-profile';
import { Product } from '../retail/products/product-types';
// Mock product data for On running shoes
const onRunningProducts: Product[] = [
    {
        id: 'cloud-x-3',
        name: 'Cloud X 3',
        description: 'The Cloud X 3 is the latest evolution of the award-winning Cloud X.',
        price: 159.99,
        image: '/images/products/on-running/cloud-x-3.jpg',
        sizes: ['7', '8', '9', '10', '11', '12'],
        inStock: true,
        category: 'Running Shoes',
        brand: 'On Running',
        sku: 'ON-CX3-001',
    },
    {
        id: 'cloudflow',
        name: 'Cloudflow',
        description: 'Light, fast and fully cushioned for training and racing.',
        price: 139.99,
        image: '/images/products/on-running/cloudflow.jpg',
        sizes: ['7', '8', '9', '10', '11', '12'],
        inStock: true,
        category: 'Running Shoes',
        brand: 'On Running',
        sku: 'ON-CF-001',
    },
];
// Mock purchase history
const purchaseHistory = [
    {
        product: onRunningProducts[0],
        date: new Date('2023-11-15'),
        quantity: 1,
    },
    {
        product: onRunningProducts[1],
        date: new Date('2023-09-20'),
        quantity: 1,
    },
];
// Mock user data
export const DEMO_USER_ID = 'emma123';
export const mockCustomerProfile: CustomerProfile = {
    id: DEMO_USER_ID,
    name: 'Emma Thompson',
    email: 'emma@example.com',
    phone: '+1 (555) 123-4567',
    address: {
        street: '123 Market Street',
        city: 'San Francisco',
        state: 'CA',
        zipCode: '94105',
        country: 'USA',
    },
    paymentMethods: [
        {
            id: 'card-001',
            type: 'credit_card',
            lastFour: '4242',
            isDefault: true,
        },
        {
            id: 'card-002',
            type: 'credit_card',
            lastFour: '8888',
            isDefault: false,
        },
    ],
    customerPreferences: {
        sustainabilityFocus: true,
        preferredCategories: ['Running', 'Athletic Shoes', 'Sustainable Fashion'],
        shoppingFrequency: 'monthly',
        deliveryPreferences: {
            timeSlot: 'afternoon',
            contactless: true,
        },
    },
    sustainabilityScore: 85,
    loyaltyTier: 'gold',
    circularEconomyStats: {
        refillParticipation: true,
        returnRate: 0.05,
        carbonSaved: 125.5,
        packagingReduced: 8.2,
    },
    membershipStatus: 'Gold',
    visitCount: 15,
    loyaltyPoints: 2500,
    history: {
        purchases: purchaseHistory.map((order): void => ({
            product: order.product,
            date: new Date(order.date),
            quantity: 1,
        })),
        views: [
            {
                product: onRunningProducts[0],
                date: new Date('2023-11-14'),
                duration: 180,
            },
            {
                product: onRunningProducts[1],
                date: new Date('2023-11-13'),
                duration: 240,
            },
        ],
        returns: [],
    },
    preferences: {
        products: ['Cloud X 3', 'Cloudflow'],
        categories: ['Running', 'Athletic Shoes'],
        brands: ['On Running'],
        sustainability: {
            packaging: true,
            ingredients: true,
            manufacturing: true,
        },
    },
    settings: {
        notifications: true,
        marketingEmails: true,
        smsNotifications: true,
        pushNotifications: true,
        language: 'en',
        currency: 'USD',
        theme: 'dark',
        accessibility: {
            fontSize: 16,
            contrast: 'normal',
            animations: true,
        },
    },
    addresses: [
        {
            id: 'addr-001',
            address: '123 Market Street',
            city: 'San Francisco',
            state: 'CA',
            zipCode: '94105',
            country: 'USA',
            isDefault: true,
        },
    ],
};
// Mock service implementation
export const _mockUserService = {
    getUserProfile: async (userId: string): Promise<CustomerProfile> => {
        if (userId === DEMO_USER_ID) {
            return mockCustomerProfile;
        }
        throw new Error('User not found');
    },
    updateUserProfile: async (userId: string, updates: Partial<CustomerProfile>): Promise<CustomerProfile> => {
        if (userId === DEMO_USER_ID) {
            return { ...mockCustomerProfile, ...updates };
        }
        throw new Error('User not found');
    },
};
