import React, { useState, useEffect } from 'react'
import { useKnowledgeGraph } from '@/hooks/use-knowledge-graph'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/shared/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui/table'
import { Badge } from '@/shared/components/ui/badge'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/shared/components/ui/tabs'
import { LineChart, BarChart } from '@/shared/components/charts'

interface AgentMetrics {
  successRate: number
  errorRate: number
  latency: number
  requestsPerMinute: number
  lastUpdated: string
}

interface Agent {
  id: string
  name: string
  type: string
  status: 'active' | 'inactive' | 'error'
  metrics: AgentMetrics
  deployedVersion: string
  lastDeployed: string
}

interface Alert {
  id: string
  agentId: string
  severity: 'critical' | 'warning' | 'info'
  message: string
  timestamp: string
  status: 'active' | 'resolved'
}

export const AgentDashboardPage: React.FC = () => {
  const kgClient = useKnowledgeGraph()
  const [agents, setAgents] = useState<Agent[]>([])
  const [alerts, setAlerts] = useState<Alert[]>([])
  const [selectedTimeRange, setSelectedTimeRange] = useState('24h')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedAgents, fetchedAlerts] = await Promise.all([
          kgClient.getDeployedAgents(),
          kgClient.getAgentAlerts(),
        ])
        setAgents(fetchedAgents)
        setAlerts(fetchedAlerts)
      } catch (error) {
        console.error('Error fetching dashboard data:', error)
      }
    }

    fetchData()
    const interval = setInterval(fetchData, 30000) // Refresh every 30 seconds
    return () => clearInterval(interval)
  }, [kgClient])

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'bg-green-500/10 text-green-700'
      case 'inactive':
        return 'bg-yellow-500/10 text-yellow-700'
      default:
        return 'bg-red-500/10 text-red-700'
    }
  }

  const getSeverityColor = (severity: string) => {
    switch (severity) {
      case 'critical':
        return 'bg-red-500/10 text-red-700'
      case 'warning':
        return 'bg-yellow-500/10 text-yellow-700'
      default:
        return 'bg-blue-500/10 text-blue-700'
    }
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">Agent Dashboard</h1>
        <div className="flex items-center space-x-4">
          <select
            value={selectedTimeRange}
            onChange={(e) => setSelectedTimeRange(e.target.value)}
            className="border rounded p-1"
          >
            <option value="1h">Last Hour</option>
            <option value="24h">Last 24 Hours</option>
            <option value="7d">Last 7 Days</option>
            <option value="30d">Last 30 Days</option>
          </select>
        </div>
      </div>

      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Total Agents</CardTitle>
            <CardDescription>Deployed agents count</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">{agents.length}</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Active Agents</CardTitle>
            <CardDescription>Currently running</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-green-600">
              {agents.filter((a) => a.status === 'active').length}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Active Alerts</CardTitle>
            <CardDescription>Unresolved issues</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-yellow-600">
              {alerts.filter((a) => a.status === 'active').length}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Average Success Rate</CardTitle>
            <CardDescription>Across all agents</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-blue-600">
              {(
                agents.reduce(
                  (acc, agent) => acc + agent.metrics.successRate,
                  0
                ) / agents.length
              ).toFixed(1)}
              %
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Main Content */}
      <Tabs defaultValue="overview" className="space-y-6">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="performance">Performance</TabsTrigger>
          <TabsTrigger value="alerts">Alerts</TabsTrigger>
        </TabsList>

        <TabsContent value="overview">
          <Card>
            <CardHeader>
              <CardTitle>Deployed Agents</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Type</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Success Rate</TableHead>
                    <TableHead>Version</TableHead>
                    <TableHead>Last Deployed</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {agents.map((agent) => (
                    <TableRow key={agent.id}>
                      <TableCell className="font-medium">
                        {agent.name}
                      </TableCell>
                      <TableCell>{agent.type}</TableCell>
                      <TableCell>
                        <Badge className={getStatusColor(agent.status)}>
                          {agent.status}
                        </Badge>
                      </TableCell>
                      <TableCell>{agent.metrics.successRate}%</TableCell>
                      <TableCell>{agent.deployedVersion}</TableCell>
                      <TableCell>
                        {new Date(agent.lastDeployed).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="performance">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Card>
              <CardHeader>
                <CardTitle>Success Rate Over Time</CardTitle>
              </CardHeader>
              <CardContent>
                <LineChart
                  data={agents.map((agent) => ({
                    name: agent.name,
                    value: agent.metrics.successRate,
                  }))}
                />
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Requests per Minute</CardTitle>
              </CardHeader>
              <CardContent>
                <BarChart
                  data={agents.map((agent) => ({
                    name: agent.name,
                    value: agent.metrics.requestsPerMinute,
                  }))}
                />
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Average Latency</CardTitle>
              </CardHeader>
              <CardContent>
                <LineChart
                  data={agents.map((agent) => ({
                    name: agent.name,
                    value: agent.metrics.latency,
                  }))}
                />
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Error Rate</CardTitle>
              </CardHeader>
              <CardContent>
                <LineChart
                  data={agents.map((agent) => ({
                    name: agent.name,
                    value: agent.metrics.errorRate,
                  }))}
                />
              </CardContent>
            </Card>
          </div>
        </TabsContent>

        <TabsContent value="alerts">
          <Card>
            <CardHeader>
              <CardTitle>Recent Alerts</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Severity</TableHead>
                    <TableHead>Agent</TableHead>
                    <TableHead>Message</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Time</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {alerts.map((alert) => (
                    <TableRow key={alert.id}>
                      <TableCell>
                        <Badge className={getSeverityColor(alert.severity)}>
                          {alert.severity}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        {agents.find((a) => a.id === alert.agentId)?.name}
                      </TableCell>
                      <TableCell>{alert.message}</TableCell>
                      <TableCell>
                        <Badge
                          className={
                            alert.status === 'active'
                              ? 'bg-red-500/10 text-red-700'
                              : 'bg-green-500/10 text-green-700'
                          }
                        >
                          {alert.status}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        {new Date(alert.timestamp).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  )
}
