import React, { useMemo, useRef } from 'react'
import { useVoiceProcessor } from '../hooks/useVoiceProcessor'
import { VoiceContext } from './voice-context'
import type {
  VoiceContextType,
  VoiceProviderProps,
} from './voice-context-types'

export function VoiceProvider({
  children,
  onError,
}: VoiceProviderProps): JSX.Element {
  const {
    isListening,
    transcript,
    confidence,
    lastCommand,
    error,
    startListening: startVoiceProcessor,
    stopListening: stopVoiceProcessor,
    clearError: clearVoiceError,
    clearTranscript: clearVoiceTranscript,
    clearLastCommand: clearVoiceCommand,
    reset: resetVoiceProcessor,
  } = useVoiceProcessor({
    onError,
    initialState: {
      isListening: false,
      transcript: '',
      confidence: 0,
      lastCommand: undefined,
      error: undefined,
    },
  })

  const [isLoading, setIsLoading] = React.useState(false)
  const startingRef = useRef(false)

  React.useEffect(() => {
    if (error && onError) {
      onError(error)
    }
  }, [error, onError])

  const value = useMemo(
    (): VoiceContextType => ({
      isListening,
      isLoading,
      transcript,
      confidence,
      lastCommand,
      error,
      startListening: async () => {
        if (startingRef.current || isListening) {
          return
        }
        try {
          startingRef.current = true
          setIsLoading(true)
          await startVoiceProcessor()
        } catch (error) {
          console.error('Failed to start voice processor:', error)
          if (onError && error instanceof Error) {
            onError(error)
          }
          throw error
        } finally {
          setIsLoading(false)
          startingRef.current = false
        }
      },
      stopListening: () => {
        if (!isListening) {
          return
        }
        stopVoiceProcessor()
      },
      clearError: clearVoiceError,
      clearTranscript: clearVoiceTranscript,
      clearLastCommand: clearVoiceCommand,
      reset: () => {
        startingRef.current = false
        resetVoiceProcessor()
      },
    }),
    [
      isListening,
      isLoading,
      transcript,
      confidence,
      lastCommand,
      error,
      startVoiceProcessor,
      stopVoiceProcessor,
      clearVoiceError,
      clearVoiceTranscript,
      clearVoiceCommand,
      resetVoiceProcessor,
      onError,
    ]
  )

  return <VoiceContext.Provider value={value}>{children}</VoiceContext.Provider>
}
