import React from 'react'
import { Navigate } from 'react-router-dom'
import { _AdminLayout as AdminLayout } from './components/layout/admin-layout'
import { _DashboardPage as DashboardPage } from './components/dashboard/dashboard-page'
import { _InboundListPage as InboundListPage } from './components/inbound/inbound-list-page'
import { _InboundDetailPage as InboundDetailPage } from './components/inbound/inbound-detail-page'
import { _RefillProgramPage as RefillProgramPage } from './components/refills/refill-program-page'
import { _RefillOverviewPage as RefillOverviewPage } from './components/refills/refill-overview-page'
import { _RefillPackagingFlowsPage as RefillPackagingFlowsPage } from './components/refills/refill-packaging-flows-page'
import { _RefillReturnInformationPage as RefillReturnInformationPage } from './components/refills/refill-return-information-page'
import { _RefillBoxDetailPage as RefillBoxDetailPage } from './components/refills/refill-box-detail-page'
import { _RepairProgramPage as RepairProgramPage } from './components/repairs/repair-program-page'
import { _RepairOverviewPage as RepairOverviewPage } from './components/repairs/repair-overview-page'
import { _RepairsListPage as RepairsListPage } from './components/repairs/repairs-list-page'
import { _RepairDetailPage as RepairDetailPage } from './components/repairs/repair-detail-page'
import { _RepairTechniciansPage as RepairTechniciansPage } from './components/repairs/repair-technicians-page'
import { _RepairAnalyticsPage as RepairAnalyticsPage } from './components/repairs/repair-analytics-page'
import { _RecycleProgramPage as RecycleProgramPage } from './components/recycle/recycle-program-page'
import { _RecycleOverviewPage as RecycleOverviewPage } from './components/recycle/recycle-overview-page'
import { _RecycleFinancialsPage as RecycleFinancialsPage } from './components/recycle/recycle-financials-page'
import { _RecycleEnvironmentPage as RecycleEnvironmentPage } from './components/recycle/recycle-environment-page'
import { _RecycleInformationPage as RecycleInformationPage } from './components/recycle/recycle-information-page'
import { _RecycleDetailPage as RecycleDetailPage } from './components/recycle/recycle-detail-page'
import { _AILearningPage as AILearningPage } from './components/ai-learning/ai-learning-page'
import { _AILearningStreamDetail as AILearningStreamDetail } from './components/ai-learning/ai-learning-stream-detail'
import { _ResaleDetailPage as ResaleDetailPage } from './components/resale/resale-detail-page'
import { _ResaleEnvironmentPage as ResaleEnvironmentPage } from './components/resale/resale-environment-page'
import { _ResaleFinancialsPage as ResaleFinancialsPage } from './components/resale/resale-financials-page'
import { _ResaleInformationPage as ResaleInformationPage } from './components/resale/resale-information-page'
import { _ResaleOverviewPage as ResaleOverviewPage } from './components/resale/resale-overview-page'
import { _ResaleProgramPage as ResaleProgramPage } from './components/resale/resale-program-page'
import AgentBuilderPage from './components/agent-builder/agent-builder-page'
import { AgentDashboardPage } from './components/agent-dashboard/agent-dashboard-page'

const routes = [
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      { index: true, element: <DashboardPage /> },
      { path: 'inbound', element: <InboundListPage /> },
      { path: 'inbound/:id', element: <InboundDetailPage /> },
      {
        path: 'refills',
        element: <RefillProgramPage />,
        children: [
          { index: true, element: <Navigate to="overview" replace /> },
          { path: 'overview', element: <RefillOverviewPage /> },
          { path: 'packaging-flows', element: <RefillPackagingFlowsPage /> },
          {
            path: 'return-information',
            element: <RefillReturnInformationPage />,
          },
          { path: 'box/:id', element: <RefillBoxDetailPage /> },
        ],
      },
      {
        path: 'repairs',
        element: <RepairProgramPage />,
        children: [
          { index: true, element: <Navigate to="overview" replace /> },
          { path: 'overview', element: <RepairOverviewPage /> },
          { path: 'list', element: <RepairsListPage /> },
          { path: 'technicians', element: <RepairTechniciansPage /> },
          { path: 'analytics', element: <RepairAnalyticsPage /> },
          { path: ':id', element: <RepairDetailPage /> },
        ],
      },
      {
        path: 'recycle',
        element: <RecycleProgramPage />,
        children: [
          { index: true, element: <Navigate to="overview" replace /> },
          { path: 'overview', element: <RecycleOverviewPage /> },
          { path: 'financials', element: <RecycleFinancialsPage /> },
          { path: 'environment', element: <RecycleEnvironmentPage /> },
          { path: 'recycle-information', element: <RecycleInformationPage /> },
          {
            path: 'recycle-information/:id',
            element: <RecycleDetailPage />,
          },
        ],
      },
      {
        path: 'resale',
        element: <ResaleProgramPage />,
        children: [
          { index: true, element: <Navigate to="overview" replace /> },
          { path: 'overview', element: <ResaleOverviewPage /> },
          { path: 'financials', element: <ResaleFinancialsPage /> },
          { path: 'environment', element: <ResaleEnvironmentPage /> },
          { path: 'resale-information', element: <ResaleInformationPage /> },
          { path: 'resale-information/:id', element: <ResaleDetailPage /> },
        ],
      },
      { path: 'ai-learning', element: <AILearningPage /> },
      {
        path: 'ai-learning/stream/:stream',
        element: <AILearningStreamDetail />,
      },
      { path: 'agent-builder', element: <AgentBuilderPage /> },
      { path: 'agent-dashboard', element: <AgentDashboardPage /> },
    ],
  },
]

export function AdminRoutes() {
  return routes
}
